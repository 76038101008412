import React, { useState, useEffect } from 'react';
import InfoBoxes from '../../../../components/stats/Infobox/InfoBoxes';
import classes from '../../../../css/layout.module.css';
import { formatTimestampDiff } from '../../../../share/function/util';

const QueueStats = (props) => {
	const { data } = props;
	const [stats, setStats] = useState({
		'Total Messages Queueing': null,
		'Total SMS Queueing': null,
		'Total IM Chat Queueing': null,
		'Oldest Message': null,
	});

	useEffect(() => {
		const smsTotal = getQueueingAmount(data.smsQueues);
		const imTotal = getQueueingAmount(data.imQueues);
		const oldestMessageTimestamp = getOldestMessage(data);
		setStats({
			'Total Messages Queueing': smsTotal + imTotal,
			'Total SMS Queueing': smsTotal,
			'Total IM Chat Queueing': imTotal,
			'Oldest Message':
				oldestMessageTimestamp !== Number.MAX_SAFE_INTEGER
					? formatTimestampDiff(oldestMessageTimestamp)
					: 'N/A',
		});
	}, [data]);

	const getQueueingAmount = (data) => {
		return data.reduce((acc, cur) => acc + cur.pendingMessagesCount, 0);
	};

	const getOldestMessage = (data) => {
		const array = data.smsQueues.concat(data.imQueues);
		return array.reduce((acc, cur) => {
			return cur.oldestMessageTimestamp
				? cur.oldestMessageTimestamp < acc
					? cur.oldestMessageTimestamp
					: acc
				: acc;
		}, Number.MAX_SAFE_INTEGER);
	};

	return <InfoBoxes className={classes.stats_infoboxes_im} stats={stats} />;
};

export default QueueStats;
