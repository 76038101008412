/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getIMStatsRequest } from '../../../../../axios/api';
import Aux from '../../../../../hoc/aux/Aux';
import Filter from './Filter';
import Report from './Report';

const IMStats = () => {
	const [webParam, setWebParam] = useState({
		brand: null,
		filter: 'today',
	});
	const { brand, filter } = webParam;
	const [data, setData] = useState(null);

	useEffect(() => {
		getIMStats();
	}, [brand, filter]);

	const getIMStats = () => {
		if (brand && filter) {
			getIMStatsRequest(webParam).then((res) => setData(res.data));
		}
	};

	const selectOnChange = (name, event) => {
		const index = event.selectedIndex;
		setWebParam({ ...webParam, brand: event[index].value });
	};

	const refreshButtonOnClick = () => {
		getIMStats();
	};

	const timeButtonOnClick = (value) => {
		setWebParam({ ...webParam, filter: value });
	};

	const render = data ? <Report data={data} /> : '';

	return (
		<Aux>
			<h1>IM Chat Stats</h1>
			<Filter
				selectOnChange={selectOnChange}
				selected={filter}
				timeButtonOnClick={timeButtonOnClick}
				refreshButtonOnClick={refreshButtonOnClick}
			/>
			{render}
		</Aux>
	);
};

export default IMStats;
