/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getIMCustomersRequest } from '../../../../../axios/api';
import Aux from '../../../../../hoc/aux/Aux';
import CustomerTable from './CustomerTable';
import Filter from './Filter';

const Customers = () => {
	const [webParam, setWebParam] = useState({
		client: null,
		brand: null,
		email: '',
	});
	const [paging, setPaging] = useState({
		page: 0,
		size: 25,
		sort: "id,DESC"
	});
	const [tableData, setTableData] = useState({
		data: null,
		maxPage: 0,
	});
	useEffect(() => {
		getIMCustomer();
	}, [paging]);

	const pagination = (type) => {
		if (type === 'ADD') {
			setPaging({ ...paging, page: paging.page + 1 });
		} else if (type === 'SUB') {
			if (webParam.page !== 0) {
				setPaging({ ...paging, page: paging.page - 1 });
			}
		}
	};

	const getIMCustomer = () => {
		getIMCustomersRequest(webParam, paging).then((res) => {
			const data = res.data;
			setTableData({ data: data.content, maxPage: data.totalPages });
		});
	};

	const selectOnChange = (name, event) => {
		const index = event.selectedIndex;
		const { value } = event[index];
		switch (name) {
			case 'Client':
				setWebParam({ ...webParam, client: value });
				break;
			case 'Brand':
				setWebParam({ ...webParam, brand: value });
				break;
			default:
				return;
		}
	};

	const inputOnChange = (event) => {
		setWebParam({ ...webParam, email: event.target.value });
	};

	return (
		<Aux>
			<h1>IM Chat Customers</h1>
			<Filter
				client={webParam.client}
				inputValue={webParam.email}
				selectOnChange={selectOnChange}
				searchOnClick={getIMCustomer}
				inputOnChange={inputOnChange}
			/>
			<hr />
			<CustomerTable
				tableData={tableData}
				page={paging.page}
				size={paging.size}
				paginationOnClick={pagination}
			/>
		</Aux>
	);
};

export default Customers;
