import React from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { loginRequest, newCodeRequest } from '../../axios/api';
import { loginState, authState } from '../../share/state/AuthState';
import AuthForm from '../../components/auth/AuthForm';
import {
	LOGIN_FORM_RENDERS,
	TWO_FACTOR_CODE_FORM_RENDERS,
} from '../../components/auth/FormRenders';
import Auth from '../../components/auth/Auth';

const Login = () => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [login, setLogin] = useRecoilState(loginState);
	const [auth, setAuth] = useRecoilState(authState);
	const { username, password, code, success } = login;
	//onChange
	const loginFormOnChange = (event) => {
		var key = event.target.name;
		var value = event.target.value;
		setLogin({ ...login, [key]: value });
	};
	//onClick
	const forgottenPasswordClickHandler = () => {
		history.push({ pathname: '/forgottenpassword' });
	};

	const loginFormSubmit = (event) => {
		event.preventDefault();
		loginRequest(username, password, code).then((res) => {
			if (res.status === 202) {
				setLogin({ ...login, success: true });
			} else if (res.status === 200) {
				const data = res.data;
				setAuth({
					...auth,
					auth: true,
					username: data.username,
					privileges: data.privileges,
				});
			}
		}).catch(err=>{
			
		})
	};

	const newCodeRequestSubmit = (event) => {
		event.preventDefault();
		newCodeRequest(username).then((res) => {
			enqueueSnackbar('Please check your email for your new code.', {
				variant: 'success',
			});
		});
	};
	//render
	let render = success ? (
		<AuthForm
			render={TWO_FACTOR_CODE_FORM_RENDERS(
				code,
				loginFormOnChange,
				newCodeRequestSubmit
			)}
			onClick={loginFormSubmit}
		/>
	) : (
		<AuthForm
			render={LOGIN_FORM_RENDERS(
				username,
				password,
				loginFormOnChange,
				forgottenPasswordClickHandler
			)}
			onClick={loginFormSubmit}
		/>
	);

	return <Auth render={render} />;
};

export default Login;
