import React from 'react';
import classes from '../../css/layout.module.css';

const Auth = (props) => {
	return (
		<div>
			<main className={classes.homepage_left}>
				<header className={classes.homepage_header_mobile}>
					<h2>
						<span>Allstar.</span>Chat
					</h2>
				</header>
				{props.render}
			</main>
			<aside className={classes.homepage_right}>
				<header>
					<h3>Welcome to</h3>
					<h1>
						<span>Allstar.</span>Chat
					</h1>
				</header>
			</aside>
		</div>
	);
};

export default Auth;
