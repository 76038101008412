import React, { useEffect, useState } from 'react';
import { getSMSActivityRequest } from '../../../../../axios/api';
import Aux from '../../../../../hoc/aux/Aux';
import ActivityTable from './ActivityTable';

const SmsActivity = (props) => {
	const { onClick } = props;
	const [data, setData] = useState();

	useEffect(() => {
		getSmsActivity();
		const interval = setInterval(() => {
			getSmsActivity();
		}, 15000);
		return () => clearInterval(interval);
	}, []);

	const getSmsActivity = () => {
		getSMSActivityRequest().then((res) => {
			setData(res.data);
		});
	};

	return (
		<Aux>
			<h1>SMS Activity</h1>
			<ActivityTable data={data} onClick={onClick} />
		</Aux>
	);
};

export default SmsActivity;
