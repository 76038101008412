import React from 'react';
import classes from '../../../css/layout.module.css';

const InfoBox = (props) => {
	const { name, value } = props;

	return (
		<div className={classes.stats_infobox}>
			<div>
				<h3>{name}</h3>
				<div className={classes.stats_infobox_number}>{value}</div>
			</div>
		</div>
	);
};

export default InfoBox;
