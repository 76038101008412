/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import DataTable from '../../../../../components/dataTable/DataTable';
import Button from '../../../../../components/ui/Button';
import classes from '../../../../../css/layout.module.css';
import {
	DATE_TIME_FORMAT,
	MESSAGE_DETAILS_CLASS,
} from '../../../../../enum/enum';
import {
	formatTimestamp,
	getColourClass,
} from '../../../../../share/function/util';
import { authState } from '../../../../../share/state/AuthState';
import { overleyState } from '../../../../../share/state/OverlayState';
import MessageOverview from './Message/MessageOverview';

const ActivityTable = (props) => {
	const tableHeaders = {
		[classes.tb_colour]: '',
		tb_time: 'Time',
		tb_from: 'From',
		tb_to: 'To',
		tb_service: 'Service',
		[classes.tb_content]: 'Content',
		tb_status: 'Status',
		tb_status_text: 'Status Text',
		[classes.tb_view]: '',
	};
	const { data, onClick } = props;
	const [auth] = useRecoilState(authState);
	const setOverlay = useSetRecoilState(overleyState);
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
	});

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, []);

	useEffect(() => {
		if (data) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: data.map((it) => {
						const data = {
							Time: formatTimestamp(+it.time, DATE_TIME_FORMAT),
							From: it.from,
							To: it.to,
							Service: it.service,
							Message: it.message,
							Status: it.status,
							'Status Text': renderStatusText(
								it.status,
								it.statusText
							),
						};
						return formatTbodyTr(
							data,
							it.id,
							it.direction,
							it.free ? 0 : 1
						);
					}),
				};
			});
		}
	}, [data]);

	const formatThead = () => {
		return (
			<tr>
				{Object.entries(tableHeaders).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	};

	const formatTbodyTr = (data, id, direction, free) => {
		const formatTd = (title, value, index) => {
			if (title === 'Status') {
				value = formatStatus(value);
			}
			return (
				<td headers='tb_status' key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span className={classes.tb_content}>{value}</span>
				</td>
			);
		};
		return (
			<tr key={'tr-' + id} className={getColourClass(direction, free)}>
				<td headers='tb_colour'></td>
				{Object.entries(data).map(([key, value], index) => {
					return formatTd(key, value, index);
				})}
				{auth.privileges.find(
					(el) => el === 'VIEW - MOBILE NUMBERS'
				) ? (
					<td headers='tb_view'>
						<Button
							func={() => renderMessageDetails(id, direction)}
							text={'View'}
						/>
					</td>
				) : null}
			</tr>
		);
	};

	const formatStatus = (status) => {
		let statusClass = '';
		switch (status) {
			case 'PROCESSING':
			case 'SENT':
				statusClass = classes.status_sending;
				break;
			case 'COMPLETED':
				status = 'DELIVERED';
			// eslint-disable-next-line no-fallthrough
			case 'AUTORESPONDED':
			case 'DELIVERED':
				statusClass = classes.status_delivered;
				break;
			case 'APPROVED':
				statusClass = classes.status_queued;
				break;
			case 'AWAITING_APPROVAL':
			case 'WAITING':
				statusClass = classes.status_waiting;
				break;
			case 'MAX_RETRY':
			case 'DENIED':
			case 'UNROUTEABLE':
			case 'DEAD':
			case 'NO_REPORT':
			case 'FAILED':
				statusClass = classes.status_failed;
				break;
			default: {
			}
		}
		return (
			<span className={classes.tb_content}>
				<span className={statusClass}>{status}</span>
			</span>
		);
	};

	const renderStatusText = (status, statusText) => {
		statusText = statusText ? `- ${statusText}` : '';
		switch (status) {
			case 'APPROVED':
				return 'Queued';
			case 'DENIED':
				return 'Message rejected';
			case 'PROCESSING':
			case 'AWAITING_APPROVAL':
				return 'In process';
			case 'AUTORESPONDED':
				return 'AutoResponded';
			case 'UNROUTEABLE':
				return "Can't route message";
			case 'SENT':
				return 'Waiting for receipt';
			case 'DEAD':
				return `Message Cancelled ${statusText}`;
			case 'FAILED':
				return `Message will be retried later ${statusText}`;
			case 'MAX_RETRY':
				return 'Message Cancelled - Maximum Retries';
			case 'WAITING':
				return 'Waiting for previous part';
			case 'COMPLETED':
				return 'Successfully delivered';
			case 'NO_REPORT':
				return 'No receipt found after 72hrs';
			default:
				return 'Unknown status should be gone soon';
		}
	};

	const renderMessageDetails = (id, direction) => {
		setOverlay({
			class: MESSAGE_DETAILS_CLASS,
			render: (
				<MessageOverview
					onClick={onClick}
					id={id}
					direction={direction}
				/>
			),
		});
	};

	return (
		<section className={classes.activity_results}>
			<DataTable
				className={classes.activity_results_table}
				data={tbodyData}
			/>
		</section>
	);
};

export default ActivityTable;
