import React from 'react';
import classes from '../../css/layout.module.css';

const Button = (props) => {
	const { value, className, text, func } = props;

	const classNameList = [classes.button];
	if (className) {
		classNameList.push(className);
	}

	let onClick;
	if (func) {
		onClick = () => func();
	}

	return (
		<button
			value={value}
			className={classNameList.join(' ')}
			onClick={onClick}
		>
			{text}
		</button>
	);
};

export default Button;
