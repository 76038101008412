import React, { useCallback } from 'react';
import Aux from '../../../../../hoc/aux/Aux';
import classes from '../../../../../css/layout.module.css';
import InfoBoxes from '../../../../../components/stats/Infobox/InfoBoxes';
import Table from './Table';

const Report = (props) => {
	const { data } = props;

	const getPercentDiffSpan = useCallback((diff) => {
		let className = classes.increase;
		if (diff < 0) {
			className = classes.reduction;
		}
		return <span className={className}>{diff}%</span>;
	}, []);

	const calculateDiff = useCallback(
		(value, prevValue) => {
			return getPercentDiffSpan(
				(((value - prevValue) / prevValue) * 100).toFixed(2)
			);
		},
		[getPercentDiffSpan]
	);

	const currencyList = data.averageCustomerValue.map((it) => it.currency);

	const infoboxData = {
		'New Customers': data.newCustomerCount,
		'Total Purchases': data.bundlePurchases.reduce(
			(acc, cur) => acc + cur.sold,
			0
		),
	};

	const revenueData = {
		headers: {
			tb_currency: 'Currency',
			tb_averagecustomerpurchase: 'Average Customer Purchase',
			tb_totalrevenue: 'Total Revenue',
			tb_lifetimecustomervalue: 'Lifetime Customer Value',
		},
		bodyData: currencyList.map((it) => {
			const getAmountByCurrency = (data) => {
				const el = data.find((el) => el.currency === it);
				return el ? +el.amount.toFixed(2) : 0;
			};
			const span = (data, diff) => (
				<span>
					{data} {diff}
				</span>
			);
			const averageSpend = getAmountByCurrency(data.averageSpend);
			const prevAverageSpend = getAmountByCurrency(
				data.previousAverageSpend
			);
			const monthlySpend = getAmountByCurrency(data.monthlySpend);
			const prevMonthlySpend = getAmountByCurrency(data.monthlySpend);

			return {
				Currency: it.toUpperCase(),
				'Average Customer Purchase': span(
					averageSpend,
					calculateDiff(averageSpend, prevAverageSpend)
				),
				'Total Revenue': span(
					monthlySpend,
					calculateDiff(monthlySpend, prevMonthlySpend)
				),
				'Lifetime Customer Value': getAmountByCurrency(
					data.averageCustomerValue
				),
			};
		}),
	};

	const bundlePurchasesData = {
		headers: {
			tb_bundle: 'Bundle',
			tb_cost: 'Cost',
			tb_sold: 'Sold',
			tb_revenue: 'Revenue',
			tb_payment_provider: 'Payment Provider',
			tb_change: '(+/-)',
		},
		bodyData: data.bundlePurchases.map((bp) => {
			const el = data.previousBundlePurchases.find(
				(el) => el.description === bp.description
			);
			return {
				Bundle: bp.description,
				Cost: bp.currency + bp.cost,
				Sold: bp.sold,
				Revenue: bp.currency + (bp.cost * bp.sold).toFixed(2),
				'Payment Provider': bp.paymentProvider,
				'%(+/-)': el
					? calculateDiff(bp.sold, el.sold)
					: getPercentDiffSpan('NEW'),
			};
		}),
	};

	const MessageObjectFormat = (type, value, prevValue) => {
		return {
			'Message type': type,
			Amount: value,
			'%(+/-)': calculateDiff(value, prevValue),
		};
	};

	const messagesData = {
		headers: {
			tb_messagetype: 'Message type',
			tb_amount: 'Amount',
			tb_change_messages: '% (+/-)',
		},
		bodyData: [
			MessageObjectFormat(
				'Messages Received',
				data.totalMessagesReceived,
				data.previousTotalMessagesReceived
			),
			MessageObjectFormat(
				'Messages Sent',
				data.totalMessagesSent,
				data.previousTotalMessagesSent
			),
			MessageObjectFormat(
				'Messages Charged',
				data.totalCharged,
				data.previousTotalCharged
			),
			MessageObjectFormat(
				'Messages Free',
				data.totalFree,
				data.previousTotalFree
			),
		],
	};

	const topSpendersData = {
		headers: {
			tb_customer: 'Customer',
			tb_amount_topspenders: 'Amount',
		},
		bodyData: data.topSpenders.map((ts) => {
			return {
				Customer: ts.name,
				Amount: ts.currency + ts.spend.toFixed(2),
			};
		}),
	};

	const activeCustomersData = {
		headers: {
			tb_customer_active: 'Customer',
			tb_messages_active: 'Messages',
		},
		bodyData: data.sentPerCustomerList.map((c) => {
			return {
				Customer: `${c.firstName} ${c.lastName}`,
				Messages: c.messages,
			};
		}),
	};

	return (
		<Aux>
			<h3 className={classes.stats_header}>IM Customer Report</h3>
			<InfoBoxes
				className={classes.stats_InfoBoxes_im}
				stats={infoboxData}
			/>
			<Table title='Revenue' data={revenueData} />
			<Table title='Bundle Purchases' data={bundlePurchasesData} />
			<Table title='Messages' data={messagesData} />
			<Table title='Top Spenders' data={topSpendersData} />
			<Table title='Active Customers' data={activeCustomersData} />{' '}
		</Aux>
	);
};

export default Report;
