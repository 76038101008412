import React from 'react';
import classes from '../../css/layout.module.css';

const Overlay = (props) => {
	const { render } = props;
	return (
		<div>
			<div id={classes.overlay_right} />
			{render}
		</div>
	);
};

export default Overlay;
