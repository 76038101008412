import React from 'react';

import InfoBox from './InfoBox';
import classes from '../../../css/layout.module.css';
import Section from '../../page/Section';

const InfoBoxes = (props) => {
	const { className, stats } = props;

	let infoBoxes = Object.entries(stats).map(([key, value]) => {
		value = value !== null ? value : '...';
		return <InfoBox key={key} name={key} value={value} />;
	});

	return (
		<Section
			className={[classes.stats_infoboxes, className].join(' ')}
			render={infoBoxes}
		/>
	);
};

export default InfoBoxes;
