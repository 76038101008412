import React, { useState, useEffect } from 'react';
import classes from '../../../../../css/layout.module.css';
import Buttons from '../../../../../components/filter/buttons/Buttons';
import FilterSection from '../../../../../components/filter/searchFilter/SearchFilter';
import Button from '../../../../../components/ui/Button';
import { getIMBrandRequest } from '../../../../../axios/api';
import Select from '../../../../../components/filter/Select';
import { timeButtons } from '../../../../../components/filter/buttons/ButtonsRender';

const Filter = (props) => {
	const {
		selectOnChange,
		selected,
		timeButtonOnClick,
		refreshButtonOnClick,
	} = props;
	const [brandData, setBrandData] = useState(null);

	useEffect(() => {
		getIMBrandRequest().then((res) => {
			setBrandData(res.data.content);
		});
	}, []);

	const getSelect = (data, name) => {
		return (
			<Select
				label={`Select ${name}`}
				key={name}
				data={data}
				selectOnChange={selectOnChange}
				name={name}
			/>
		);
	};

	let filtersRender = [getSelect(brandData, 'brand')];

	let timeButtonsRender = (
		<Buttons
			render={timeButtons}
			selected={selected}
			buttonOnClick={timeButtonOnClick}
		/>
	);

	let refreshButtonRender = (
		<Button
			func={() => refreshButtonOnClick()}
			text={<span>Refresh</span>}
		/>
	);

	return (
		<FilterSection
			form={classes.stats_filter}
			filtersRender={filtersRender}
			leftExtra={timeButtonsRender}
			rightExtra={refreshButtonRender}
		/>
	);
};

export default Filter;
