import React from 'react';
import classes from '../../css/layout.module.css';
import Button from '../ui/Button';

const AuthForm = (props) => {
	const { render, onClick } = props;

	let inputs = render.inputs.map((it, index) => {
		let topText = null;
		let input = null;
		if (it.topText) {
			topText = <p>{it.topText}</p>;
		}
		if (it.input) {
			input = (
				<input
					type={it.input.type}
					name={it.input.name}
					placeholder={it.input.label}
					autoComplete={it.input.label}
					onChange={it.input.onChange}
					value={it.input.value}
				/>
			);
		}
		return (
			<div key={index}>
				{topText}
				{input}
			</div>
		);
	});

	return (
		<div className={classes.form_outer}>
			<h2>{render.h2}</h2>
			<form className={classes.form_fields} onSubmit={onClick}>
				<fieldset>
					<div id='credentials'>{inputs}</div>
					<div>
						<Button text={render.submitButtonText} />
					</div>
					<div
						className={classes.form_error}
						style={{ visibility: 'hidden' }}
					>
						Error Message
					</div>
					{render.extra}
				</fieldset>
			</form>
		</div>
	);
};

export default AuthForm;
