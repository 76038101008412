/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from '../../../css/layout.module.css';
import listItemRenders from '../../../components/sidebar/ListItemRenders';
import ListItem from '../../../components/sidebar/ListItem';
import Timer from '../../../components/sidebar/Timer';
import { authState } from '../../../share/state/AuthState';
import { logout } from '../../../axios/api';
import { useRecoilState } from 'recoil';
import { menuState } from '../../../share/state/SidebarState';

const SideBar = (props) => {
	const [auth, setAuth] = useRecoilState(authState);
	const [menus, setMenus] = useRecoilState(menuState);
	const listItems = listItemRenders();
	const { sidebar, subMenuOnClick } = props;

	const menuOnClick = (id) => {
		const newObject = Object.entries(menus).reduce(
			(object, [key, { display }]) => {
				object[key] = {
					display: id === key ? !display : false,
				};
				return object;
			},
			{}
		);
		setMenus({ ...newObject });
	};

	const logoutOnClick = () => {
		logout().then(() => {
			setAuth({ auth: false, username: '', privileges: [] });
		});
	};

	let renders = listItems.map((it, index) => {
		const name = it.a.dataParentname;
		let menu = null;
		if (name) {
			menu = Object.entries(menus).find((key) => key[0] === name)[1];
		}
		return (
			<ListItem
				key={index}
				listItem={it}
				menu={menu}
				menuOnClick={menuOnClick}
				subMenuOnClick={subMenuOnClick}
			/>
		);
	});

	return (
		<aside
			style={sidebar ? { left: 0 } : null}
			className={classes.innerpage_left}
		>
			<section className={classes.stats_navigation_top}>
				<h2>
					<span>Allstar.</span>
					<span>Chat</span>
				</h2>
				<nav id='stats_navigation_sidenav'>
					<ul>{renders}</ul>
				</nav>
			</section>

			<aside className={classes.stats_navigation_bottom}>
				<div className={classes.stats_navigation_welcome}>
					Hello,
					<br />
					{auth.username}
				</div>
				<nav>
					<a href='' onClick={logoutOnClick}>
						Logout
					</a>
				</nav>
				<Timer />
			</aside>
		</aside>
	);
};

export default SideBar;
