import React, { useEffect, useState } from 'react';
import Aux from '../../../../hoc/aux/Aux';
import QueueStats from './QueueStats';
import Refresh from './Refresh';
import { getQueuesInfomationRequest } from '../../../../axios/api';
import QueueTable from './QueueTable';

const Queue = () => {
	const [data, setData] = useState(null);

	useEffect(() => {
		getQueuesInformation();
	}, []);

	const getQueuesInformation = () => {
		setData(null);
		getQueuesInfomationRequest().then((res) => {
			setData(res.data);
		});
	};

	const refreshOnClick = () => {
		getQueuesInformation();
	};

	let render = null;
	if (data) {
		render = (
			<Aux>
				<QueueStats data={data} />
				<Refresh onClick={refreshOnClick} />
				<QueueTable data={data.smsQueues} title='SMS Queue' />
				<QueueTable data={data.imQueues} title='IM Queue'/>
			</Aux>
		);
	}

	return (
		<Aux>
			<h1>Queues</h1>
			{render}
		</Aux>
	);
};

export default Queue;
