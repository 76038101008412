import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import classes from '../../../css/layout.module.css';

const BarChart = (props) => {
	return (
		<Doughnut
			id={classes.doughnut_canvas}
			data={props.data}
			width={400}
			height={400}
		/>
	);
};

export default BarChart;
