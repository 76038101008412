/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getSMSThreadRequest } from '../../../../../../axios/api';
import DataTable from '../../../../../../components/dataTable/DataTable';
import classes from '../../../../../../css/layout.module.css';
import { getColourClass } from '../../../../../../share/function/util';

const MessageThread = (props) => {
	const headers = ['From', 'To', 'Service', 'Content', 'Status', 'ID'];
	const { id, direction } = props;
	const [tableData, setTableData] = useState({
		thead: null,
		tbody: null,
	});

	useEffect(() => {
		getMessageThreadRequest();
	}, [id, direction]);

	const getMessageThreadRequest = () => {
		const webParam = {
			id: id,
			direction: direction,
		};
		getSMSThreadRequest(webParam).then((res) => {
			formatHead(headers);
			formatTbody(res.data);
		});
	};

	const formatMessageThreadData = (data) => {
		if (data.direction === 1) {
			return {
				From: data.originator,
				To: data.cli,
				Service: data.service,
				Content: data.body,
				Status: data.status,
				ID: data.messageId,
			};
		} else {
			return {
				From: data.cli,
				To: data.originator,
				Service: data.service,
				Content: data.body,
				Status: data.status,
				ID: data.messageId,
			};
		}
	};

	const formatHead = (headers) => {
		const formatTh = (data, idClass) => (
			<th key={`th-${data}`} id={idClass}>
				{data}
			</th>
		);
		const tr = [formatTh(null, classes.tb_colour_threads)];
		headers.forEach((header) => {
			tr.push(formatTh(header));
		});
		setTableData((prev) => {
			return {
				...prev,
				thead: <tr>{tr}</tr>,
			};
		});
	};

	const formatTbody = (data) => {
		const formatTr = (className, data, index) => (
			<tr key={`tr-${index}`} className={className}>
				{data}
			</tr>
		);
		const formatTd = (data, trIndex, tdIndex, idClass) => (
			<td key={`td-${trIndex}-${tdIndex}`} headers={idClass}>
				{data}
			</td>
		);

		const trs = data.map((data, trIndex) => {
			const formatted = formatMessageThreadData(data);
			let tds = [formatTd(null, 0, 0, 'tb_colour_threads')];
			Object.entries(formatted).forEach(([key, value], tdIndex) => {
				tds.push(formatTd(value, trIndex, tdIndex + 1));
			});
			return formatTr(
				getColourClass(data.direction, data.charge),
				tds,
				trIndex
			);
		});

		setTableData((prev) => {
			return {
				...prev,
				tbody: trs,
			};
		});
	};

	return (
		<DataTable
			className={classes.details_popup_table_threads}
			data={tableData}
		/>
	);
};

export default MessageThread;
