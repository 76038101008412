import { atom } from 'recoil';

export const authState = atom({
	key: 'auth',
	default: { auth: false, username: '', privileges: [] },
});

export const loginState = atom({
	key: 'login',
	default: { username: '', password: '', code: '', success: false },
});

export const forgottenPasswordState = atom({
	key: 'forgotPassword',
	default: { username: '', success: false },
});

export const changePasswordState = atom({
	key: 'changePassword',
	default: { password: '', confirmPassword: '', success: false },
});
