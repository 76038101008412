import React from 'react';
import { getSearchFilterStyle } from './SearchFilterStyle';

const FilterSection = (props) => {
	const {
		singlecolumn,
		form,
		filtersRender,
		leftExtra,
		rightRender,
		asideClassName,
	} = props;
	const { aside, divLeft, divRight } = getSearchFilterStyle(singlecolumn);

	return (
		<aside className={[aside, asideClassName].join(' ')}>
			<div className={divLeft}>
				<div>
					<form className={form}>
						<fieldset>{filtersRender}</fieldset>
					</form>
					{leftExtra}
				</div>
			</div>
			<div className={divRight}>{rightRender}</div>
		</aside>
	);
};

export default FilterSection;
