/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Pagination from '../../../../../components/dataTable/Pagination';
import DataTable from '../../../../../components/dataTable/DataTable';
import classes from '../../../../../css/layout.module.css';
import Aux from '../../../../../hoc/aux/Aux';
import { formatTimestamp } from '../../../../../share/function/util';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../../enum/enum';

const CustomerTable = (props) => {
	const tableHeaders = {
		tb_created: 'Created',
		tb_name: 'Name',
		tb_cli: 'CLI',
		tb_dob: 'DOB',
		tb_service: 'Service',
		tb_status: 'Status',
		tb_id: 'ID',
	};
	const { tableData, page, size, paginationOnClick } = props;
	const { data, maxPage } = tableData;
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
	});

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, []);

	useEffect(() => {
		if (data) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: data.map((it) => {
						const data = {
							Created: formatTimestamp(
								it.created,
								DATE_TIME_FORMAT
							),
							Name: it.name,
							CLI: it.number,
							DOB: it.dob
								? formatTimestamp(it.dob, DATE_FORMAT)
								: '',
							Service: it.service,
							Status: it.status,
							ID: it.id,
						};
						return formatTbodyTr(data, it.id);
					}),
				};
			});
		}
	}, [data]);

	const formatThead = () => {
		return (
			<tr>
				{Object.entries(tableHeaders).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	};

	const formatTbodyTr = (data, id) => {
		const formatTd = (title, value, index) => {
			return (
				<td key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span className={classes.tb_content}>{value}</span>
				</td>
			);
		};
		return (
			<tr key={'tr-' + id}>
				{Object.entries(data).map(([key, value], index) => {
					return formatTd(key, value, index);
				})}
			</tr>
		);
	};

	const render = (
		<Aux>
			<h3>Showing {data?data.length:"..."} Results</h3>
			<DataTable
				className={classes.customers_results_table}
				data={tbodyData}
			/>
			<Pagination
				current={page}
				last={maxPage}
				paginationOnClick={paginationOnClick}
			/>
		</Aux>
	);

	return (
		<section className={classes.customers_results}>
			{tbodyData.tbody
				? tbodyData.tbody.length !== 0
					? render
					: 'No Data Available'
				: ''}
		</section>
	);
};

export default CustomerTable;
