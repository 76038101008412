import React from 'react';

const Section = (props) => {
	const { className, title, render } = props;

	let titleRender = null;
	if (title) {
		titleRender = <h3>{title}</h3>;
	}

	return (
		<section className={className}>
			{titleRender}
			{render}
		</section>
	);
};

export default Section;
