import {
	DAILY_BUTTON,
	HOURLY_BUTTON,
	LAST_MONTH_BUTTON,
	LAST_WEEK_BUTTON,
	MONTHLY_BUTTON,
	THIS_MONTH_BUTTON,
	THIS_WEEK_BUTTON,
	TODAY_BUTTON,
	WEEKLY_BUTTON,
	YESTERDAY_BUTTON,
} from '../../../enum/enum';

export const timeButtons = [
	{
		name: 'Today',
		value: TODAY_BUTTON,
	},
	{
		name: 'Yesterday',
		value: YESTERDAY_BUTTON,
	},
	{
		name: 'This Week',
		value: THIS_WEEK_BUTTON,
	},
	{
		name: 'Last Week',
		value: LAST_WEEK_BUTTON,
	},
	{
		name: 'This Month',
		value: THIS_MONTH_BUTTON,
	},
	{
		name: 'Last Month',
		value: LAST_MONTH_BUTTON,
	},
];

export const periodicityButtons = [
	{
		name: 'Hourly',
		value: HOURLY_BUTTON,
	},
	{
		name: 'Daily',
		value: DAILY_BUTTON,
	},
	{
		name: 'Weekly',
		value: WEEKLY_BUTTON,
	},
	{
		name: 'Monthly',
		value: MONTHLY_BUTTON,
	},
];
