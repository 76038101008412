import React, { useState, useEffect } from 'react';
import {
	getClientsRequest,
	getServicesRequest,
} from '../../../../../axios/api';
import DateSelector from '../../../../../components/filter/DateSelector';
import Input from '../../../../../components/filter/Input';
import FilterSection from '../../../../../components/filter/searchFilter/SearchFilter';
import Select from '../../../../../components/filter/Select';
import Button from '../../../../../components/ui/Button';
import classes from '../../../../../css/layout.module.css';

const Filter = (props) => {
	const {
		selectedClient,
		searchOnClick,
		selectOnChange,
		inputValue,
		inputOnChange,
		dateOnChange,
		dateRange,
	} = props;
	const [clientData, setClientData] = useState([]);
	const [serviceData, setServiceData] = useState([]);

	useEffect(() => {
		getClientsRequest().then((res) => {
			setClientData(res.data.content);
		});
	}, []);

	useEffect(() => {
		if (selectedClient) {
			getServicesRequest(selectedClient).then((res) => {
				setServiceData(res.data.content);
			});
			setServiceData([]);
		}
	}, [selectedClient]);

	const filtersRender = [
		<Select
			key='client'
			label='Client'
			name='Client'
			selectOnChange={selectOnChange}
			data={clientData}
		/>,
		selectedClient ? (
			<Select
				key='service'
				label='Service'
				name='Service'
				selectOnChange={selectOnChange}
				data={serviceData}
			/>
		) : null,
		<Input
			key='cli'
			value={inputValue}
			name='CLI'
			type='text'
			placeholder='Enter a CLI'
			onChange={inputOnChange}
		/>,
		<DateSelector
			key='dateSelector'
			dateRange={dateRange}
			divClass={classes.date_column}
			dateOnChange={dateOnChange}
		/>,
	];

	const rightRender = (
		<Button func={() => searchOnClick()} text={<span>Search</span>} />
	);

	return (
		<FilterSection
			singlecolumn
			form={classes.customers_filter}
			filtersRender={filtersRender}
			rightRender={rightRender}
		/>
	);
};

export default Filter;
