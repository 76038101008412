/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getSMSKeywordRequest } from '../../../../../axios/api';
import DataTable from '../../../../../components/dataTable/DataTable';
import classes from '../../../../../css/layout.module.css';
import {
	DAILY_BUTTON,
	HOURLY_BUTTON,
	MONTHLY_BUTTON,
	WEEKLY_BUTTON,
} from '../../../../../enum/enum';
import { numToMonth } from '../../../../../share/function/util';

const KeywordsTable = (props) => {
	const defaultTableData = {
		thead: [],
		tbody: [],
	};
	const { webParam } = props;
	const { filter } = webParam;
	const [tableData, setTableData] = useState(defaultTableData);

	useEffect(() => {
		setTableData(defaultTableData);
		getSMSKeywordRequest(webParam).then((res) => {
			let data = res.data;
			const array = data[0].stats;
			const periods = calculateTrow1(array.map((it) => it.period));
			const units = calculateTrow2(array.map((it) => it.unit));
			data = formatTbody(formatData(data), units.props.children.length + 1);
			setTableData((prev) => {
				return { ...prev, thead: [periods, units] };
			});
			setTableData((prev) => {
				return { ...prev, tbody: data };
			});
		});
	}, [webParam]);

	const formatData = (data) => {
		return data.reduce((array, keywordsStats) => {
			const { keyword, stats } = keywordsStats;
			const { name, service, route } = keyword;
			const index = array.findIndex((it) => it.serviceName === service);
			const keywordStats = {
				name: name,
				route: route,
				stats: stats,
			};

			if (index === -1) {
				const keywords = [keywordStats];
				array.push({ serviceName: service, keywords: keywords });
			} else {
				const keywords = array[index];
				keywords.keywords.push(keywordStats);
				array[index] = keywords;
			}
			return array;
		}, []);
	};

	const calculateTrow1 = (periods) => {
		let tr1 = [
			<th className={classes.keyword_th_keyword} key='tr1-0' rowSpan='2'>
				Keyword
			</th>,
		];
		const periodCount = periods
			.map((it) => {
				let period = it;
				if (filter === DAILY_BUTTON || filter === WEEKLY_BUTTON) {
					period = numToMonth(period);
				}
				return period;
			})
			.reduce((acc, curr) => {
				acc[curr] ? acc[curr]++ : (acc[curr] = 1);
				return acc;
			}, {});

		const rows = Object.entries(periodCount).map(([key, value], index) => {
			return (
				<th key={'tr1-' + index + 1} colSpan={value}>
					{key}
				</th>
			);
		});
		return <tr key={'tr1'}>{tr1.concat(rows)}</tr>;
	};

	const calculateTrow2 = (units) => {
		const tr2 = units.map((it, index) => {
			let unit = it;
			if (filter === MONTHLY_BUTTON) {
				unit = numToMonth(unit);
			} else if (filter === HOURLY_BUTTON) {
				unit = unit < 10 ? '0' + unit : unit;
			}
			return <th key={'tr2-' + index}>{unit}</th>;
		});
		return <tr key={'tr2'}>{tr2}</tr>;
	};

	const formatTbody = (data, length) => {
		let newTbody = [];
		const tr = (index, value) => (
			<tr key={`tr-${newTbody.length}-${index}`}>{value}</tr>
		);
		const td = (value, index, headers) => (
			<td headers={headers} key={`td-${newTbody.length}-${index}`}>
				{value}
			</td>
		);

		data.forEach((it, index) => {
			const th = (
				<th colSpan={length} className={classes.keyword_sub_heading}>
					{it.serviceName}
				</th>
			);
			const array = it.keywords.map((it, statsIndex) => {
				const tds = [td(it.name, 0, 'keyword_th_keyword')];
				it.stats.reduce((array, it, index) => {
					array.push(td(it.messages, index + 1));
					return array;
				}, tds);
				return tr(`stats-${statsIndex}`, tds);
			});
			newTbody.push(tr(`header-${index}`, th), ...array);
		});
		return newTbody;
	};

	return (
		<section className={classes.stats_sms_keywords}>
			<h3>Showing All Results</h3>
			<div className={classes.stats_sms_keywords_table}>
				<DataTable data={tableData} />
			</div>
		</section>
	);
};

export default KeywordsTable;
