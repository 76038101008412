import React, { useState } from 'react';
import Aux from '../../../../../hoc/aux/Aux';
import Filter from './Filter';
import KeywordsTable from './KeywordsTable';
import monent from 'moment';

const Keywords = () => {
	const [webParam, setWebParam] = useState({
		filter: 'daily',
		start: monent()
			.utcOffset(0)
			.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
			.valueOf(),
	});

	const timeButtonOnClick = (value) => {
		setWebParam({ ...webParam, filter: value });
	};

	return (
		<Aux>
			<h1>Keyword Stats</h1>
			<Filter
				selected={webParam.filter}
				timeButtonOnClick={timeButtonOnClick}
			/>
			<KeywordsTable webParam={webParam} />
		</Aux>
	);
};

export default Keywords;
