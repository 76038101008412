import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { authState } from '../share/state/AuthState';

const useHttpErrorHandler = (axios) => {
	const setAuth = useSetRecoilState(authState);
	const { enqueueSnackbar } = useSnackbar();

	const handleClick = (msg) => {
		enqueueSnackbar(`Error: ${msg}`, {
			variant: 'error',
		});
	};

	const reqInterceptor = axios.interceptors.request.use((req) =>
		setCsrfOnRequest(req)
	);
	const resInterceptor = axios.interceptors.response.use(
		(res) => setCsrfOnResponse(res),
		(error) => handleErrors(error)
	);

	const setCsrfOnRequest = (req) => {
		req.headers['x-csrf-token'] = Cookies.get('_csrf');
		return req;
	};

	const setCsrfOnResponse = (res) => {
		Cookies.set('_csrf', res.headers['x-csrf-token']);
		return res;
	};

	//Error Handling
	const handleErrors = (err) => {
		switch (err.response.status) {
			case 401:
				if (err.response.config.url.startsWith('/api/')) {
					setAuth({ auth: false, username: '', privileges: [] });
					window.location = '/';
				}
				handleClick(err.response.data.message);
				break;
			case 400:
				handleClick(err.response.data.errors);
				break;
			default:
		}
		return Promise.reject(err);
	};

	useEffect(() => {
		return () => {
			axios.interceptors.request.eject(reqInterceptor);
			axios.interceptors.response.eject(resInterceptor);
		};
	}, [reqInterceptor, resInterceptor, axios]);
};

export default useHttpErrorHandler;
