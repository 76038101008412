import classes from '../css/layout.module.css';

// Auth
export const LOGIN_FORM = 'LOGIN_FORM';
export const FORGOTTEN_PASSWORD_FORM = 'FORGOTTEN_PASSWORD_FORM';
export const CHANGE_PASSWORD_FORM = 'CHANGE_PASSWORD_FORM';
export const TWO_FACTOR_CODE_FORM = 'TWO_FACTOR_CODE_FORM';
// SubMenu
export const DASHBOARD = 'DASHBOARD';
export const SMS_STATS = 'SMS_STATS';
export const SMS_CUSTOMERS = 'SMS_CUSTOMER';
export const SMS_ACTIVITY = 'SMS_ACTIVITY';
export const SMS_CUSTOMER_CARE = 'SMS_CUSTOMER_CARE';
export const SMS_KEYWORDS = 'SMS_KEYWORDS';
export const IM_STATS = 'IM_STATS';
export const IM_CUSTOMERS = 'IM_CUSTOMERS';
export const IM_ACTIVITY = 'IM_ACTIVITY';
export const CONSOLE_QUEUES = 'QUEUES';
export const SUPPORT_CONTACT_ADMIN = 'CONTACT_ADMIN';
// Overview Stats
export const OVERVIEW_INBOUND = 'Total Inbound';
export const OVERVIEW_PSMS = 'Total PSMS Delivered';
export const OVERVIEW_CLIS = "Unique CLI's";
export const OVERVIEW_QUEUEING = 'Total Queueing';
export const OVERVIEW_CUSTOMERS = 'New Customers';
export const OVERVIEW_REVENUE = 'Average Revenue/User';
export const OVERVIEW_BULK = 'Total Bulk Delivered';
export const OVERVIEW_STOP = 'Stop Requests';
// TimeButtons
export const TODAY_BUTTON = 'today';
export const YESTERDAY_BUTTON = 'yesterday';
export const THIS_WEEK_BUTTON = 'this-week';
export const LAST_WEEK_BUTTON = 'last-week';
export const THIS_MONTH_BUTTON = 'this-month';
export const LAST_MONTH_BUTTON = 'last-month';
export const HOURLY_BUTTON = 'hourly';
export const DAILY_BUTTON = 'daily';
export const WEEKLY_BUTTON = 'weekly';
export const MONTHLY_BUTTON = 'monthly';
// DateTime
export const DATE_FORMAT = 'DD|MM|YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIMEZONE_FORMAT = 'z';
export const DATE_TIME_FORMAT = 'DD|MM|YYYY HH:mm:ss';
// Message
export const MESSAGE_THREAD_CLASS = `${classes.sms_messages_popup} ${classes.sms_messages_threads}`;
export const MESSAGE_DETAILS_CLASS = `${classes.sms_messages_popup} ${classes.sms_messages_details}`;
export const MESSAGE_THREAD = 'MESSAGE_THREAD';
export const MESSAGE_DETAILS = 'MESSAGE_DETAILS';
// Privilege
export const SMS_STATS_PRIVILEGE = 'SMS - STATS';
export const SMS_CUSTOMERS_PRIVILEGE = 'SMS - CUSTOMERS';
export const SMS_ACTIVITY_PRIVILEGE = 'SMS - ACTIVITY';
export const SMS_CUSTOMER_CARE_PRIVILEGE = 'SMS - CUSTOMER CARE';
export const SMS_KEYWORDS_PRIVILEGE = 'SMS - KEYWORDS';
export const IM_STATS_PRIVILEGE = 'IM - STATS';
export const IM_CUSTOMERS_PRIVILEGE = 'IM - CUSTOMERS';
export const IM_ACTIVITY_PRIVILEGE = 'IM - ACTIVITY';
export const QUEUE_PRIVILEGE = 'QUEUE';
