import React, { useState, useEffect } from 'react';
import { getIMActivityRequest } from '../../../../../axios/api';
import Aux from '../../../../../hoc/aux/Aux';
import ActivityTable from './ActivityTable';

const IMActivity = () => {
	const [data, setData] = useState(null);

	useEffect(() => {
		getIMActivity();
		const interval = setInterval(() => {
			getIMActivity();
		}, 15000);
		return () => clearInterval(interval);
	}, []);

	const getIMActivity = () => {
		getIMActivityRequest().then((res) => {
			setData(res.data.content);
		});
	};

	return (
		<Aux>
			<h1>Chat Activity</h1>
			<ActivityTable data={data} />
		</Aux>
	);
};

export default IMActivity;
