/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from '../../css/layout.module.css';
import iconHamburger from '../../assets/images/icon_hamburger.svg';
import iconClose from '../../assets/images/icon_close.svg';
import { useRecoilState } from 'recoil';
import { sidebarState } from '../../share/state/SidebarState';

const Content = (props) => {
	const [sidebar, setSidebar] = useRecoilState(sidebarState);
	const { render } = props;

	const sideBarOnClick = () => {
		setSidebar(!sidebar);
	};

	let style = {
		background: `url(${iconHamburger}) center center no-repeat`,
		backgroundSize: `100% auto`,
	};

	if (sidebar) {
		style = {
			background: `url(${iconClose}) center center no-repeat`,
			backgroundSize: `1.149rem auto`,
		};
	}
	return (
		<main className={classes.innerpage_right}>
			<nav className={classes.mobile_nav}>
				<h2>
					<span>Allstar.</span>
					<span>Chat</span>
				</h2>
				<a
					style={style}
					onClick={sideBarOnClick}
					className={classes.hoverable}
					id='button_hamburger'
					aria-label='Show the side menu'
				>
					Menu
				</a>
			</nav>
			{render}
		</main>
	);
};

export default Content;
