/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getSMSCustomerRequest } from '../../../../../axios/api';
import Aux from '../../../../../hoc/aux/Aux';
import Filter from './Filter';
import CustomerTable from './CustomerTable';

const SmsCustomers = () => {
	const [webParam, setWebParam] = useState({
		client: null,
		service: null,
		status: null,
		cli: '',
	});
	const [paging, setPaging] = useState({
		page: 0,
		size: 25,
	});
	const [tableData, setTableData] = useState({
		data: null,
		maxPage: 0,
	});

	useEffect(() => {
		getSmsCustomer();
	}, [paging]);

	const pagination = (type) => {
		if (type === 'ADD') {
			setPaging({ ...paging, page: paging.page + 1 });
		} else if (type === 'SUB') {
			if (webParam.page !== 0) {
				setPaging({ ...paging, page: paging.page - 1 });
			}
		}
	};

	const getSmsCustomer = () => {
		getSMSCustomerRequest(webParam, paging).then((res) => {
			const data = res.data;
			setTableData({ data: data.content, maxPage: data.totalPages });
		});
	};

	const selectOnChange = (name, event) => {
		const index = event.selectedIndex;
		const { value } = event[index];
		switch (name) {
			case 'Client':
				setWebParam({ ...webParam, client: value });
				break;
			case 'Service':
				setWebParam({ ...webParam, service: value });
				break;
			case 'Status':
				setWebParam({ ...webParam, status: value });
				break;
			default:
				return;
		}
	};

	const inputOnChange = (event) => {
		setWebParam({ ...webParam, cli: event.target.value });
	};

	return (
		<Aux>
			<h1>SMS Customers</h1>
			<Filter
				inputValue={webParam.cli}
				selectOnChange={selectOnChange}
				searchOnClick={getSmsCustomer}
				inputOnChange={inputOnChange}
				selectedClient={webParam.client}
			/>
			<hr />
			<CustomerTable
				tableData={tableData}
				page={paging.page}
				size={paging.size}
				paginationOnClick={pagination}
			/>
		</Aux>
	);
};

export default SmsCustomers;
