import React from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

import { changePasswordState } from '../../share/state/AuthState';
import { changePasswordRequest } from '../../axios/api';
import AuthForm from '../../components/auth/AuthForm';
import {
	CHANGE_PASSWORD_FORM_RENDERS,
	CHANGE_PASSWORD_FORM_SUCCESS_RENDERS,
} from '../../components/auth/FormRenders';
import Auth from '../../components/auth/Auth';

const ChangePassword = (props) => {
	const history = useHistory();
	const [changePassword, setChangePassword] = useRecoilState(
		changePasswordState
	);
	const { password, confirmPassword, success } = changePassword;
	//onChange
	const changePasswordFormOnChange = (event) => {
		var key = event.target.name;
		var value = event.target.value;
		setChangePassword({ ...changePassword, [key]: value });
	};
	//onClick
	const redirectToHomeClickHandler = () => {
		setChangePassword({ ...ChangePassword, success: true });
		history.push({ pathname: '/' });
	};

	const changePasswordFormSubmit = (event) => {
		event.preventDefault();
		const token = new URLSearchParams(window.location.search).get('token');
		changePasswordRequest(token, password, confirmPassword).then((res) => {
			setChangePassword({ ...changePassword, success: true });
		});
	};
	//render
	let render = success ? (
		<AuthForm
			render={CHANGE_PASSWORD_FORM_SUCCESS_RENDERS()}
			onClick={redirectToHomeClickHandler}
		/>
	) : (
		<AuthForm
			render={CHANGE_PASSWORD_FORM_RENDERS(
				password,
				confirmPassword,
				changePasswordFormOnChange
			)}
			onClick={changePasswordFormSubmit}
		/>
	);

	return <Auth render={render} />;
};

export default ChangePassword;
