/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
	getbulkRequest,
	getNetworkStatsRequest,
	getNewCustomerRequest,
	getPremiumSmsRequest,
	getQueuingRequest,
	getRevenuePerCustomerRequest,
	getSMSBreakdownRequest,
	getStopRequest,
	getTotalInboundRequest,
	getUniqueCLIRequest,
} from '../../../../../axios/api';
import {
	OVERVIEW_BULK,
	OVERVIEW_CLIS,
	OVERVIEW_CUSTOMERS,
	OVERVIEW_INBOUND,
	OVERVIEW_PSMS,
	OVERVIEW_QUEUEING,
	OVERVIEW_REVENUE,
	OVERVIEW_STOP,
} from '../../../../../enum/enum';
import Filter from './Filter';
import InfoBoxes from '../../../../../components/stats/Infobox/InfoBoxes';
import NetworkStats from './NetworkStats';
import SmsBreakdown from './SMSBreakdown';
import { getCurrency } from '../../../../../share/function/util';
import Aux from '../../../../../hoc/aux/Aux';

const SmsStats = () => {
	const defaultOverviewStats = {
		[OVERVIEW_INBOUND]: null,
		[OVERVIEW_PSMS]: null,
		[OVERVIEW_CLIS]: null,
		[OVERVIEW_QUEUEING]: null,
		[OVERVIEW_CUSTOMERS]: null,
		[OVERVIEW_REVENUE]: null,
		[OVERVIEW_BULK]: null,
		[OVERVIEW_STOP]: null,
	};
	const defaultServiceStats = { id: null, name: null };
	const [client, setClient] = useState(null);
	const [service, setService] = useState(defaultServiceStats);
	const [selected, setSelected] = useState('today');
	const [render, setRender] = useState(false);
	const [overviewStats, setOverviewStats] = useState(defaultOverviewStats);
	const [networkStats, setNetworkStats] = useState(null);
	const [smsBreakdownStats, setSmsBreakdownStats] = useState(null);

	useEffect(() => {
		if (client && service.id && selected) {
			setRender(true);
			getStats();
		}
	}, [client, service, selected]);

	const getStats = () => {
		getOverviewStats();
		networkStatsRequest();
		smsBreakdownRequest();
	};

	const selectOnChange = (name, event) => {
		const index = event.selectedIndex;
		const { text, value } = event[index];
		let newService = defaultServiceStats;
		if (name === 'client') {
			setClient(value);
		} else if (name === 'service') {
			newService = { id: value, name: text };
		}
		setService(newService);
		setRender(false);
	};

	const refreshButtonOnClick = () => {
		if (client && service.id && selected) {
			getStats();
		}
	};

	const timeButtonOnClick = (value) => {
		setSelected(value);
	};

	const getOverviewStats = () => {
		setOverviewStats(defaultOverviewStats);
		overviewStatsRequest(getTotalInboundRequest, OVERVIEW_INBOUND);
		overviewStatsRequest(getPremiumSmsRequest, OVERVIEW_PSMS);
		overviewStatsRequest(getUniqueCLIRequest, OVERVIEW_CLIS);
		overviewStatsRequest(getQueuingRequest, OVERVIEW_QUEUEING);
		overviewStatsRequest(getNewCustomerRequest, OVERVIEW_CUSTOMERS);
		overviewStatsRequest(getRevenuePerCustomerRequest, OVERVIEW_REVENUE);
		overviewStatsRequest(getbulkRequest, OVERVIEW_BULK);
		overviewStatsRequest(getStopRequest, OVERVIEW_STOP);
	};

	const overviewStatsRequest = (request, key) => {
		request(client, service.id, selected).then((res) => {
			let value = res.data;
			if (key === OVERVIEW_REVENUE) {
				const currency = getCurrency(service.name);
				value = currency + (value / 100).toFixed(2);
			}
			setOverviewStats((prevState) => {
				return {
					...prevState,
					[key]: value,
				};
			});
		});
	};

	const networkStatsRequest = () => {
		setNetworkStats(null);
		getNetworkStatsRequest(client, service.id, selected).then((res) => {
			setNetworkStats(res.data);
		});
	};

	const smsBreakdownRequest = () => {
		setSmsBreakdownStats(null);
		getSMSBreakdownRequest(client, service.id, selected).then((res) => {
			setSmsBreakdownStats(res.data);
		});
	};

	return (
		<Aux>
			<h1>SMS Stats Overview</h1>
			<Filter
				client={client}
				selectOnChange={selectOnChange}
				selected={selected}
				timeButtonOnClick={timeButtonOnClick}
				refreshButtonOnClick={refreshButtonOnClick}
			/>
			{render ? (
				<div>
					<InfoBoxes stats={overviewStats} />
					<NetworkStats data={networkStats} />
					<SmsBreakdown data={smsBreakdownStats} filter={selected} />
				</div>
			) : (
				''
			)}
		</Aux>
	);
};

export default SmsStats;
