import React from 'react';

const DataTable = (props) => {
	const { className, data } = props;
	const { thead, tbody, tfoot } = data;

	return (
		<div className={className}>
			<table>
				<thead>{thead}</thead>
				<tbody>{tbody}</tbody>
				{tfoot}
			</table>
		</div>
	);
};

export default DataTable;
