/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DataTable from '../../../../../components/dataTable/DataTable';
import Pagination from '../../../../../components/dataTable/Pagination';
import classes from '../../../../../css/layout.module.css';
import { DATE_TIME_FORMAT } from '../../../../../enum/enum';
import Aux from '../../../../../hoc/aux/Aux';
import { formatTimestamp } from '../../../../../share/function/util';

const CustomerCareTable = (props) => {
	const tableHeaders = {
		tb_date: 'Date',
		tb_cli: 'CLI',
		tb_originator: 'Originator',
		tb_service: 'Service',
		tb_status: 'Status',
		tb_detailedstatus: 'Detailed Status',
		tb_type: 'Type',
		tb_messagebody: 'Message Body',
	};
	const { tableData, page, size, paginationOnClick } = props;
	const { data, maxPage } = tableData;
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
	});

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, []);

	useEffect(() => {
		if (data) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: data.map((it) => {
						const data = {
							Date: formatTimestamp(
								it.timestamp,
								DATE_TIME_FORMAT
							),
							CLI: it.cli,
							Originator: it.originator,
							Service: it.service,
							Status: it.status,
							Detailedstatus: it.statusText,
							Type: it.type,
							MessageBody: it.body,
						};
						return formatTbodyTr(data, it.id);
					}),
				};
			});
		}
	}, [data]);

	const formatThead = () => {
		return (
			<tr>
				{Object.entries(tableHeaders).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	};

	const formatTbodyTr = (data, id) => {
		const colourCss =
			data.Type === 'INBOUND'
				? classes.inbound
				: data.Type === 'OUTBOUND - FREE'
				? classes.outbound_free
				: classes.outbound_premium;

		const formatTd = (title, value, index) => {
			return (
				<td key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span
						className={`${classes.tb_content} ${colourCss}`}
					>
						{value}
					</span>
				</td>
			);
		};
		return (
			<tr key={'tr-' + id} className={colourCss}>
				{Object.entries(data).map(([key, value], index) => {
					return formatTd(key, value, index);
				})}
			</tr>
		);
	};

	const render = (
		<Aux>
			<h3>Showing {size} Results</h3>
			<DataTable
				className={classes.customerscare_results_table}
				data={tbodyData}
			/>
			<Pagination
				current={page}
				last={maxPage}
				paginationOnClick={paginationOnClick}
			/>
		</Aux>
	);

	return (
		<section className={classes.customerscare_results}>
			{tbodyData.tbody
				? tbodyData.tbody.length !== 0
					? render
					: 'No Data Available'
				: ''}
		</section>
	);
};

export default CustomerCareTable;
