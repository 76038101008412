import React from 'react';
import classes from '../../css/layout.module.css';
import Button from '../ui/Button';

const Pagination = (props) => {
	const { current, last, paginationOnClick } = props;

	let prevButton = null;
	let nextButton = null;
	const span = (text) => <span>{text}</span>;

	if (current !== 0) {
		prevButton = (
			<div className={classes.results_pagination_button}>
				<Button
					func={() => paginationOnClick('SUB')}
					text={span('Previous Page')}
				/>
			</div>
		);
	}

	if (current + 1 !== last) {
		nextButton = (
			<div className={classes.results_pagination_button}>
				<Button
					func={() => paginationOnClick('ADD')}
					text={span('Next Page')}
				/>
			</div>
		);
	}

	return (
		<div className={classes.results_pagination}>
			{prevButton}
			<div className={classes.results_pagination_pages}>
				Page {current + 1} of {last}
			</div>
			{nextButton}
		</div>
	);
};

export default Pagination;
