import React, { useEffect, useState } from 'react';
import Section from '../../../../../components/page/Section';
import BarChart from '../../../../../components/stats/chart/BarChart';
import classes from '../../../../../css/layout.module.css';

const NetworkStats = (props) => {
	const { data } = props;
	const [render, setRender] = useState(null);

	useEffect(() => {
		if (!data || data.length === 0) {
			setRender(<div>No Data Available</div>);
			return;
		}
		const labels = data.map((data) => data.network);
		const counts = data.map((data) => data.count);
		const colors = labels.map((network) => getColor(network));
		const border = colors.map(() => '#fff');
		const chartData = {
			labels: labels,
			datasets: [
				{
					data: counts,
					backgroundColor: colors,
					borderColor: border,
					borderWidth: 2,
				},
			],
		};
		setRender(<BarChart data={chartData} />);
	}, [data]);

	const getColor = (network) => {
		switch (network) {
			case 'eeora-uk':
			case 'eetmo-uk':
			case 'UK.TMOBILE':
			case 'UK.ORANGE':
				return '#007B85';
			case 'IRELAND.3':
			case 'UK.THREE':
			case 'three-uk':
				return '#6D22E9';
			case 'IRELAND.O2':
			case 'o2-uk':
			case 'UK.O2':
				return '#003145';
			case 'IRELAND.METEOR':
				return '#F15B23';
			case 'IRELAND.TESCO':
			case 'UK.TESCO':
				return '#00539F';
			case 'sky-uk':
				return '#212FCC';
			case 'UK.VODAFONE':
			case 'voda-uk':
			case 'IRELAND.VODAFONE':
				return '#E60000';
			case 'UK.VIRGIN':
			case 'UK.VIRGIN.MTP':
			case 'virgin-uk':
			case 'IRELAND.VIRGINMEDIA':
				return '#ffea00';
			default:
				return '#F4F4F4';
		}
	};

	return (
		<Section
			className={classes.stats_doughnut_chart}
			title={'Premium SMS | Network Breakdown'}
			render={render}
		/>
	);
};

export default NetworkStats;
