import moment from 'moment';
import classes from '../../css/layout.module.css';

export const getCurrency = (serviceName) => {
	if (serviceName.includes('🇬🇧')) {
		return '£';
	} else if (serviceName.includes('🇮🇪')) {
		return '€';
	} else {
		return '';
	}
};

export const randomFourDigit = () => {
	return Math.random() * (10000 - 1000) + 1000;
};

export const formatTimestamp = (timestamp, format) => {
	return moment(timestamp).format(format);
};

export const numToMonth = (num) => {
	switch (num) {
		case 1:
			return 'Jan';
		case 2:
			return 'Feb';
		case 3:
			return 'Mar';
		case 4:
			return 'Apr';
		case 5:
			return 'May';
		case 6:
			return 'Jun';
		case 7:
			return 'Jul';
		case 8:
			return 'Aug';
		case 9:
			return 'Sep';
		case 10:
			return 'Oct';
		case 11:
			return 'Nov';
		case 12:
			return 'Dec';
		default:
			throw new Error('Unknown Month');
	}
};

export const getColourClass = (direction, free) => {
	return direction === 0
		? classes.tb_colour_blue
		: free === 0
		? classes.tb_colour_yellow
		: classes.tb_colour_red;
};

export const formatTimestampDiff = (timestamp) => {
	let format = '';
	var diff = Date.now() - timestamp;
	if (diff >= 60000) {
		if (diff >= 60000 * 60) {
			if (diff >= 3600000 * 24) {
				format = Math.floor(diff / 1000 / 60 / 60 / 24) + ' day(s)';
			} else {
				format = Math.floor(diff / 1000 / 60 / 60) + ' hr(s)';
			}
		} else {
			format = Math.floor(diff / 1000 / 60) + ' min(s)';
		}
	} else {
		format = Math.floor(diff / 1000) + ' sec(s)';
	}
	return format;
};
