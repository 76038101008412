import React from 'react';
import Section from '../../../../components/page/Section';
import Button from '../../../../components/ui/Button';
import classes from '../../../../css/layout.module.css';

const Refresh = (props) => {
	const { onClick } = props;
	let render = <Button func={() => onClick()} text={<span>Refresh</span>} />;
	return (
		<Section
			className={classes.console_queues_refresh_button}
			render={render}
		/>
	);
};

export default Refresh;
