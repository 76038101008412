import React, { useEffect, useState } from 'react';
import classes from '../../css/layout.module.css';
import monent from 'moment';
import timezone from 'moment-timezone';
import { formatTimestamp } from '../../share/function/util';
import { DATE_FORMAT, TIMEZONE_FORMAT, TIME_FORMAT } from '../../enum/enum';

const Timer = () => {
	const [timestamp, setTimestamp] = useState(monent().valueOf());

	useEffect(() => {
		const interval = setInterval(() => {
			setTimestamp(monent().valueOf());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	let date = formatTimestamp(timestamp, DATE_FORMAT);
	let zone = timezone().tz(timezone.tz.guess()).format(TIMEZONE_FORMAT);
	let time = formatTimestamp(timestamp, TIME_FORMAT);

	return (
		<div className={classes.stats_navigation_datetime}>
			{date}
			<br />
			{` ${time} ${zone}`}
		</div>
	);
};

export default Timer;
