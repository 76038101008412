/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DATE_TIME_FORMAT } from '../../../../../enum/enum';
import { formatTimestamp } from '../../../../../share/function/util';
import classes from '../../../../../css/layout.module.css';
import DataTable from '../../../../../components/dataTable/DataTable';
import Aux from '../../../../../hoc/aux/Aux';
import Pagination from '../../../../../components/dataTable/Pagination';

const CustomerTable = (props) => {
	const tableHeaders = {
		tb_created: 'Created',
		tb_name: 'Name',
		tb_email: 'Email Address',
		tb_creditbalance: 'Credit Balance',
	};
	const { tableData, page, size, paginationOnClick } = props;
	const { data, maxPage } = tableData;
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
	});

	console.log(tableData)

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, []);

	useEffect(() => {
		if (data) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: data.map((it) => {
						const data = {
							Created:
								it.creationTime !== 'Unknown'
									? formatTimestamp(
											it.creationTime,
											DATE_TIME_FORMAT
									  )
									: 'Unknown',
							Name: it.name,
							'Email Address': it.email,
							'Credit Balance': it.credits,
						};
						return formatTbodyTr(data, it.fbUid);
					}),
				};
			});
		}
	}, [data]);

	const formatThead = () => {
		return (
			<tr>
				{Object.entries(tableHeaders).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	};

	const formatTbodyTr = (data, id) => {
		const formatTd = (title, value, index) => {
			return (
				<td key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span className={classes.tb_content}>{value}</span>
				</td>
			);
		};
		return (
			<tr key={'tr-' + id}>
				{Object.entries(data).map(([key, value], index) => {
					return formatTd(key, value, index);
				})}
			</tr>
		);
	};

	const render = (
		<Aux>
			<h3>Showing {data?data.length:"..."} Results</h3>
			<DataTable
				className={classes.customers_results_im_table}
				data={tbodyData}
			/>
			<Pagination
				current={page}
				last={maxPage}
				paginationOnClick={paginationOnClick}
			/>
		</Aux>
	);

	return (
		<section className={classes.customers_results_im}>
			{tbodyData.tbody
				? tbodyData.tbody.length !== 0
					? render
					: 'No Data Available'
				: ''}
		</section>
	);
};

export default CustomerTable;