import React from 'react';
import useHttpErrorHandler from '../../hooks/http-error-handler';

const withErrorHandler = (WrappedComponed, axios) => {
	return (props) => {
		useHttpErrorHandler(axios);
		return <WrappedComponed {...props} />;
	};
};

export default withErrorHandler;
