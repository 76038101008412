import axios from './axios';
// could split this in multiple files
const endpoint = {
	homepage: '/',
	isAuthenticated: '/api',
	login: '/login',
	logout: '/logout',
	forgot_password: '/password/recovery',
	change_password: '/password/recovery',
	new_code: '/code',
	clients: '/api/clients',
	brands: '/api/im/brands',
	services: (client) => `/api/services?client=${client}`,
	sms_customer_status: '/api/sms/customer/status',
	sms_customer: '/api/sms/customer',
	sms_customer_care: '/api/sms/customer-care',
	sms_activity: '/api/sms/activity',
	sms_details: (direction, id) => `/api/sms/${direction}/${id}`,
	sms_thread: '/api/sms/messages/thread',
	im_customer: '/api/im/customers',
	im_activity: '/api/im/activity',
	im_stats: '/api/im/stats',
	queues_info: '/api/queues',
	stats_network: (client, service, filter) =>
		`/api/stats/sms/network?service=${service}&filter=${filter}&client=${client}`,
	breakdown: (client, service, filter) =>
		`/api/stats/sms/breakdown?service=${service}&filter=${filter}&client=${client}&cols=9`,
	overview: {
		total_inbound: (client, service, filter) =>
			`/api/stats/sms/overview/total-inbound?service=${service}&filter=${filter}&client=${client}`,
		psms: (client, service, filter) =>
			`/api/stats/sms/overview/psms-delivered?service=${service}&filter=${filter}&client=${client}`,
		unique_cli: (client, service, filter) =>
			`/api/stats/sms/overview/unique-cli?service=${service}&filter=${filter}&client=${client}`,
		queueing: (client, service) =>
			`/api/stats/sms/overview/total-queueing?service=${service}&filter=this-week&client=${client}`,
		new_customers: (client, service, filter) =>
			`/api/stats/sms/overview/new-customer?service=${service}&filter=${filter}&client=${client}`,
		revenue_per_customer: (client, service, filter) =>
			`/api/stats/sms/overview/average-revenue?service=${service}&filter=${filter}&client=${client}`,
		bulk: (client, service, filter) =>
			`/api/stats/sms/overview/bulk-delivered?service=${service}&filter=${filter}&client=${client}`,
		stops: (client, service, filter) =>
			`/api/stats/sms/overview/stop-request?service=${service}&filter=${filter}&client=${client}`,
	},
	sms_keywords: '/api/stats/sms/keywords',
};

export const forgotPasswordRequest = (username) => {
	return axios.post(endpoint.forgot_password, { username });
};

export const changePasswordRequest = (token, password, confirmPassword) => {
	return axios.patch(endpoint.change_password, {
		token,
		password,
		confirmPassword,
	});
};

export const loginRequest = (username, password, code) => {
	code = code.replace('-', '');
	return axios.post(endpoint.login, { username, password, code });
};

export const newCodeRequest = (username) => {
	return axios.post(endpoint.new_code, { username });
};

export const csrf = () => {
	axios.get(endpoint.homepage);
};

export const isAuthenticated = () => {
	return axios.get(endpoint.isAuthenticated)
};

export const logout = () => {
	return axios.post(endpoint.logout);
};

export const getClientsRequest = () => {
	return axios.get(endpoint.clients);
};

export const getServicesRequest = (client) => {
	return axios.get(endpoint.services(client));
};

export const getTotalInboundRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.total_inbound(client, service, filter));
};

export const getPremiumSmsRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.psms(client, service, filter));
};

export const getUniqueCLIRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.unique_cli(client, service, filter));
};

export const getQueuingRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.queueing(client, service, filter));
};

export const getNewCustomerRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.new_customers(client, service, filter));
};

export const getRevenuePerCustomerRequest = (client, service, filter) => {
	return axios.get(
		endpoint.overview.revenue_per_customer(client, service, filter)
	);
};

export const getbulkRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.bulk(client, service, filter));
};

export const getStopRequest = (client, service, filter) => {
	return axios.get(endpoint.overview.stops(client, service, filter));
};

export const getNetworkStatsRequest = (client, service, filter) => {
	return axios.get(endpoint.stats_network(client, service, filter));
};

export const getSMSBreakdownRequest = (client, service, filter) => {
	return axios.get(endpoint.breakdown(client, service, filter));
};

export const getSMSCustomerStatusRequest = () => {
	return axios.get(endpoint.sms_customer_status);
};

export const getSMSCustomerRequest = (webParam, paging) => {
	return axios.get(endpoint.sms_customer + setWebParam(webParam, paging));
};

export const getSMSKeywordRequest = (webParam) => {
	return axios.get(endpoint.sms_keywords + setWebParam(webParam));
};

export const getSMSActivityRequest = () => {
	return axios.get(endpoint.sms_activity);
};

export const getSMSDetailsRequest = (direction, id) => {
	return axios.get(endpoint.sms_details(direction, id));
};

export const getSMSThreadRequest = (webParam) => {
	return axios.get(endpoint.sms_thread + setWebParam(webParam));
};

export const getSMSCustomerCareRequest = (webParam, paging) => {
	return axios.get(
		endpoint.sms_customer_care + setWebParam(webParam, paging)
	);
};

export const getQueuesInfomationRequest = () => {
	return axios.get(endpoint.queues_info);
};

export const getIMCustomersRequest = (webParam, paging) => {
	return axios.get(endpoint.im_customer + setWebParam(webParam, paging));
};

export const getIMActivityRequest = () => {
	return axios.get(endpoint.im_activity);
};

export const getIMBrandRequest = (webParam) => {
	let url = endpoint.brands;
	if (webParam) {
		url += setWebParam(webParam);
	}
	return axios.get(url);
};

export const getIMStatsRequest = (webParam) => {
	return axios.get(endpoint.im_stats + setWebParam(webParam));
};

const setWebParam = (webParam, paging) => {
	let result = '?';
	webParam = Object.assign(webParam, paging);
	Object.entries(webParam).forEach(([key, value]) => {
		if (value != null && value !== '') {
			result += `${key}=${value}&`;
		}
	});

	return result;
};
