import React from 'react';
import classes from '../../../css/layout.module.css';
import Aux from '../../../hoc/aux/Aux';

const DashBoard = (props) => {
	return (
		<Aux>
			<h1>Dashboard</h1>

			<section className={classes.dashboard_boxes}>
				<div className={classes.dashboard_boxes_outer}>
					<div className={classes.dashboard_boxes_box}>
						<div>
							<h3>Welcome to the Allstar.Chat Client site</h3>
							<p>
								Select one of the options from the menu to get
								started
							</p>
						</div>
					</div>
					<div className={classes.dashboard_boxes_box}>
						<div>
							<h3>What's new at Allstar &gt;</h3>
							<p>
								Check out our latest product releases on the
								Allstar Status Log
							</p>
							<div className='dashboard_boxes_box_button'>
								<a href='https://allstarmediaservices.statuspage.io'>
									Status Log &gt;
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Aux>
	);
};

export default DashBoard;
