/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classes from '../../../../../../css/layout.module.css';
import DataTable from '../../../../../../components/dataTable/DataTable';
import { getSMSDetailsRequest } from '../../../../../../axios/api';

const MessageDetails = (props) => {
	const [tableData, setTableData] = useState({
		thead: null,
		tbody: null,
	});
	const { id, direction } = props;

	useEffect(() => {
		getMessageDetailsRequest();
	}, [id, direction]);

	const getMessageDetailsRequest = () => {
		let type = direction === 0 ? 'inbound' : 'outbound';
		getSMSDetailsRequest(type, id).then((res) => {
			const data = formatMessageDetailsData(direction, res.data);
			formatTbody(data);
		});
	};

	const formatMessageDetailsData = (type, data) => {
		if (type === 1) {
			return {
				ID: data.id,
				Content: data.body,
				From: data.originator,
				To: data.destination,
				'Message Id': data.messageId,
				Code: data.code,
				Status: data.status,
				Channel: data.channel,
				Created: data.created,
				Customer: data.customerId,
				Route: data.route,
				Service: data.service,
				Charge: data.charge,
			};
		} else {
			return {
				ID: data.id,
				Content: data.body,
				Keyword: data.keyword,
				From: data.originator,
				To: data.destination,
				'Message Id': data.messageId,
				Channel: data.channel,
				Queued: data.queued,
				Claimed: data.claimed,
				'Claim Time': data.claimTime,
				Answered: data.answered,
				Status: data.status,
				'Received Data': data.receivedDate,
				Created: data.created,
			};
		}
	};

	const formatTbody = (data) => {
		const formatTr = (header, data) => (
			<tr key={`tr-${header}`}>
				<th>{header}</th>
				<td>{data}</td>
			</tr>
		);
		const tbody = Object.entries(data).map(([key, value]) =>
			formatTr(key, value)
		);
		tbody.push(
			<tr key={`tr-blank`} className={classes.details_popup_blankrow}>
				<td></td>
				<td></td>
			</tr>
		);
		setTableData((prev) => {
			return {
				...prev,
				tbody: tbody,
			};
		});
	};

	return (
		<DataTable
			className={classes.details_popup_table_details}
			data={tableData}
		/>
	);
};

export default MessageDetails;
