import React from 'react';
import classes from '../../../css/layout.module.css';
import Button from '../../ui/Button';

const Buttons = (props) => {
	const { render, selected, buttonOnClick } = props;

	let buttons = render.map((it) => {
		const { value, name } = it;
		const className = value === selected ? classes.selected : '';
		return (
			<Button
				key={value}
				value={value}
				func={() => buttonOnClick(value)}
				className={className}
				text={name}
			/>
		);
	});

	return <div className={classes.stats_buttons}>{buttons}</div>;
};

export default Buttons;
