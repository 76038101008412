import React, { useEffect, useState } from 'react';
import classes from '../../../../../css/layout.module.css';
import {
	getClientsRequest,
	getServicesRequest,
} from '../../../../../axios/api';
import Buttons from '../../../../../components/filter/buttons/Buttons';
import Select from '../../../../../components/filter/Select';
import { timeButtons } from '../../../../../components/filter/buttons/ButtonsRender';
import FilterSection from '../../../../../components/filter/searchFilter/SearchFilter';
import Button from '../../../../../components/ui/Button';

const Filter = (props) => {
	const {
		client,
		selectOnChange,
		selected,
		timeButtonOnClick,
		refreshButtonOnClick,
	} = props;
	const [clientData, setClientData] = useState(null);
	const [serviceData, setServiceData] = useState(null);

	useEffect(() => {
		getClientsRequest().then((res) => {
			setClientData(res.data.content);
		});
	}, []);

	useEffect(() => {
		setServiceData(null);
		if (client) {
			getServicesRequest(client).then((res) => {
				setServiceData(res.data.content);
			});
		}
	}, [client]);

	const getSelect = (data, name) => {
		return (
			<Select
				label={`Select ${name}`}
				key={name}
				data={data}
				selectOnChange={selectOnChange}
				name={name}
			/>
		);
	};

	let filtersRender = [getSelect(clientData, 'client')];
	if (serviceData) {
		filtersRender.push(getSelect(serviceData, 'service'));
	}

	let timeButtonsRender = (
		<Buttons
			render={timeButtons}
			selected={selected}
			buttonOnClick={timeButtonOnClick}
		/>
	);

	let refreshButtonRender = (
		<Button
			func={() => refreshButtonOnClick()}
			text={<span>Refresh</span>}
		/>
	);

	return (
		<FilterSection
			form={classes.stats_filter}
			filtersRender={filtersRender}
			leftExtra={timeButtonsRender}
			rightRender={refreshButtonRender}
		/>
	);
};

export default Filter;
