import classes from '../../../css/layout.module.css';

export const getSearchFilterStyle = (singleColumn) => {
	return singleColumn ? singleColumnStyle : multiColumnStyle;
};

const singleColumnStyle = {
	aside: classes.filter_singlecolumn,
	divLeft: classes.filter_singlecolumn_left,
	divRight: classes.filter_singlecolumn_right,
};

const multiColumnStyle = {
	aside: classes.filter,
	divLeft: classes.filter_left,
	divRight: classes.filter_right,
};
