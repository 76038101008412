import React, { useState, useEffect } from 'react';
import { getClientsRequest, getIMBrandRequest } from '../../../../../axios/api';
import Input from '../../../../../components/filter/Input';
import FilterSection from '../../../../../components/filter/searchFilter/SearchFilter';
import Select from '../../../../../components/filter/Select';
import classes from '../../../../../css/layout.module.css';

const Filter = (props) => {
	const {
		client,
		inputValue,
		selectOnChange,
		searchOnClick,
		inputOnChange,
	} = props;
	const [clientData, setClientData] = useState(null);
	const [brandData, setBrandData] = useState(null);

	useEffect(() => {
		getClientsRequest().then((res) => {
			setClientData(res.data.content);
		});
	}, []);

	useEffect(() => {
		if (client) {
			getIMBrandRequest({ client }).then((res) => {
				setBrandData(res.data.content);
			});
		}
	}, [client]);

	const filtersRender = [
		<Select
			key='client'
			label='Client'
			name='Client'
			selectOnChange={selectOnChange}
			data={clientData}
		/>,
		brandData ? (
			<Select
				key='brand'
				label='Brand'
				name='Brand'
				selectOnChange={selectOnChange}
				data={brandData}
			/>
		) : null,
		<Input
			key='email'
			value={inputValue}
			name='Email'
			type='text'
			placeholder='Enter an Email'
			onChange={inputOnChange}
		/>,
	];

	const rightRender = (
		<button className={classes.button} onClick={searchOnClick}>
			<span>Search</span>
		</button>
	);

	return (
		<FilterSection
			singlecolumn
			form={classes.im_chat_filter}
			filtersRender={filtersRender}
			rightRender={rightRender}
		/>
	);
};

export default Filter;
