/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DataTable from '../../../../components/dataTable/DataTable';
import Section from '../../../../components/page/Section';
import classes from '../../../../css/layout.module.css';
import { formatTimestampDiff } from '../../../../share/function/util';

const QueueTable = (props) => {
	const tableHeaders = {
		tb_servicesmsqueue: 'Service',
		tb_totalmessagessmsqueue: 'Total Messages Queueing',
		tb_oldestmessagessmsqueue: 'Oldest Message',
	};
	const { data, title } = props;
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
		tfoot: null,
	});

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, []);

	useEffect(() => {
		if (data) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: data.map((it) => {
						if (it.pendingMessagesCount === 0) {
							return null;
						}
						const data = {
							Service: it.queueName,
							'Total Messages Queueing': it.pendingMessagesCount,
							'Oldest Message': formatTimestampDiff(
								it.oldestMessageTimestamp
							),
						};
						return formatTbodyTr(data);
					}),
					tfoot: formatTfoot(data),
				};
			});
		}
	}, [data]);

	const formatThead = () => {
		return (
			<tr>
				{Object.entries(tableHeaders).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	};

	const formatTbodyTr = (data) => {
		const formatTd = (title, value, index) => {
			return (
				<td key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span className={classes.tb_content}>{value}</span>
				</td>
			);
		};
		return (
			<tr key={'tr-' + data.queueName}>
				{Object.entries(data).map(([key, value], index) => {
					return formatTd(key, value, index);
				})}
			</tr>
		);
	};

	const formatTfoot = (data) => {
		const total = data.reduce(
			(acc, cur) => acc + cur.pendingMessagesCount,
			0
		);
		return (
			<tfoot>
				<tr>
					<td className={classes.console_queues_row_total}>
						<span className={classes.tb_content}>Total</span>
					</td>
					<td>
						<span className={classes.mobile_title}>Total msgs</span>
						<span className={classes.tb_content}>{total}</span>
					</td>
				</tr>
			</tfoot>
		);
	};

	let render = (
		<DataTable className={classes.console_queues_table} data={tbodyData} />
	);
	return (
		<Section
			className={classes.console_queues_tableouter}
			title={title}
			render={render}
		/>
	);
};

export default QueueTable;
