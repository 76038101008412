import {
	CONSOLE_QUEUES,
	DASHBOARD,
	IM_ACTIVITY,
	IM_ACTIVITY_PRIVILEGE,
	IM_CUSTOMERS,
	IM_CUSTOMERS_PRIVILEGE,
	IM_STATS,
	IM_STATS_PRIVILEGE,
	QUEUE_PRIVILEGE,
	SMS_ACTIVITY,
	SMS_ACTIVITY_PRIVILEGE,
	SMS_CUSTOMERS,
	SMS_CUSTOMERS_PRIVILEGE,
	SMS_CUSTOMER_CARE,
	SMS_CUSTOMER_CARE_PRIVILEGE,
	SMS_KEYWORDS,
	SMS_KEYWORDS_PRIVILEGE,
	SMS_STATS,
	SMS_STATS_PRIVILEGE,
	SUPPORT_CONTACT_ADMIN,
} from '../../enum/enum';
import classes from '../../css/layout.module.css';

const listItemRenders = () => {
	const listItems = [];
	listItems.push(dashboard());
	listItems.push(menuSms());
	listItems.push(menuChat());
	listItems.push(menuConsole());
	listItems.push(menuSupport());
	return listItems;
};

const dashboard = () => {
	return {
		a: {
			name: 'Dashboard',
			id: DASHBOARD,
		},
	};
};

const menuSms = () => {
	return {
		li: {
			id: 'menu_sms',
		},
		a: {
			onclick: '',
			dataParentname: 'menu_sms',
			name: 'SMS',
		},
		ul: {
			li: [
				{
					className: classes.link_sms_stats,
					aName: 'Stats',
					id: SMS_STATS,
					privilege: SMS_STATS_PRIVILEGE,
				},
				{
					className: classes.link_sms_customers,
					aName: 'Customers',
					id: SMS_CUSTOMERS,
					privilege: SMS_CUSTOMERS_PRIVILEGE,
				},
				{
					className: classes.link_sms_activity,
					aName: 'Activity',
					id: SMS_ACTIVITY,
					privilege: SMS_ACTIVITY_PRIVILEGE,
				},
				{
					className: classes.link_sms_customercare,
					aName: 'Customer Care',
					id: SMS_CUSTOMER_CARE,
					privilege: SMS_CUSTOMER_CARE_PRIVILEGE,
				},
				{
					className: classes.link_sms_keywords,
					aName: 'Keywords',
					id: SMS_KEYWORDS,
					privilege: SMS_KEYWORDS_PRIVILEGE,
				},
			],
		},
	};
};

const menuChat = () => {
	return {
		li: {
			id: 'menu_chat',
		},
		a: {
			onclick: '',
			dataParentname: 'menu_chat',
			name: 'Online Chat',
		},
		ul: {
			li: [
				{
					className: classes.link_chat_stats,
					aName: 'Stats',
					id: IM_STATS,
					privilege: IM_STATS_PRIVILEGE,
				},
				{
					className: classes.link_chat_customers,
					aName: 'Customers',
					id: IM_CUSTOMERS,
					privilege: IM_CUSTOMERS_PRIVILEGE,
				},
				{
					className: classes.link_chat_activity,
					aName: 'Activity',
					id: IM_ACTIVITY,
					privilege: IM_ACTIVITY_PRIVILEGE,
				},
			],
		},
	};
};

const menuConsole = () => {
	return {
		li: {
			id: 'menu_console',
		},
		a: {
			onclick: '',
			dataParentname: 'menu_console',
			name: 'Console',
		},
		ul: {
			li: [
				{
					className: classes.link_console_queues,
					aName: 'Queues',
					id: CONSOLE_QUEUES,
					privilege: QUEUE_PRIVILEGE,
				},
			],
		},
	};
};

const menuSupport = () => {
	return {
		li: {
			id: 'menu_support',
		},
		a: {
			onclick: '',
			dataParentname: 'menu_support',
			name: 'Support',
		},
		ul: {
			li: [
				{
					aName: 'Contact Admin',
					id: SUPPORT_CONTACT_ADMIN,
				},
			],
		},
	};
};

export default listItemRenders;
