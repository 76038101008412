import React from 'react';
import Buttons from '../../../../../components/filter/buttons/Buttons';
import { periodicityButtons } from '../../../../../components/filter/buttons/ButtonsRender';
import classes from '../../../../../css/layout.module.css';
import FilterSection from '../../../../../components/filter/searchFilter/SearchFilter';

const Filter = (props) => {
	const { selected, timeButtonOnClick } = props;

	let timeButtonsRender = (
		<Buttons
			render={periodicityButtons}
			selected={selected}
			buttonOnClick={timeButtonOnClick}
		/>
	);

	return (
		<FilterSection
			leftExtra={timeButtonsRender}
			asideClassName={classes.filter_keywords}
		/>
	);
};

export default Filter;
