/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import SideBar from './sidebar/SideBar';
import classes from '../../css/layout.module.css';
import { sidebarState } from '../../share/state/SidebarState';
import SmsStats from './content/sms/stats/SmsStats';
import DashBoard from './content/Dashboard';
import {
	CONSOLE_QUEUES,
	DASHBOARD,
	IM_ACTIVITY,
	IM_CUSTOMERS,
	IM_STATS,
	SMS_ACTIVITY,
	SMS_CUSTOMERS,
	SMS_CUSTOMER_CARE,
	SMS_KEYWORDS,
	SMS_STATS,
	SUPPORT_CONTACT_ADMIN,
} from '../../enum/enum';
import Overlay from '../../components/overlay/Overlay';
import ContactAdmin from './content/ContactAdmin';
import Content from './Content';
import SmsCustomers from '../pages/content/sms/customers/Customers';
import SMSKeywords from '../pages/content/sms/keywords/Keywords';
import SmsActivity from './content/sms/activity/Activity';
import {
	defaultOverlayState,
	overleyState,
} from '../../share/state/OverlayState';
import CustomerCare from './content/sms/customer-care/CustomerCare';
import Queue from './content/console/Queue';
import IMCustomers from './content/im/customers/Customers';
import IMActivity from './content/im/activity/Activity';
import IMStats from './content/im/stats/Stats';

const StatsPage = (props) => {
	const [sidebar, setSidebar] = useRecoilState(sidebarState);
	const [overlay, setOverlay] = useRecoilState(overleyState);
	const [content, setContent] = useState({
		class: classes.page_dashboard,
		render: <DashBoard />,
	});

	const subMenuOnClick = (id) => {
		let newContent = content;
		let newOverlay = defaultOverlayState;
		switch (id) {
			case DASHBOARD:
				newContent = {
					class: classes.page_dashboard,
					render: <DashBoard />,
				};
				break;
			case SUPPORT_CONTACT_ADMIN:
				newOverlay = {
					class: classes.contacton,
					render: <ContactAdmin onClick={overlayOnClick} />,
				};
				break;
			case SMS_STATS:
				newContent = {
					class: classes.page_sms_stats,
					render: <SmsStats />,
				};
				break;
			case SMS_CUSTOMERS:
				newContent = {
					class: classes.page_sms_customers,
					render: <SmsCustomers />,
				};
				break;
			case SMS_KEYWORDS:
				newContent = {
					class: classes.page_sms_keywords,
					render: <SMSKeywords />,
				};
				break;
			case SMS_ACTIVITY:
				newContent = {
					class: classes.page_sms_activity,
					render: <SmsActivity onClick={overlayOnClick} />,
				};
				break;
			case SMS_CUSTOMER_CARE:
				newContent = {
					class: classes.page_sms_customercare,
					render: <CustomerCare />,
				};
				break;
			case CONSOLE_QUEUES:
				newContent = {
					class: classes.page_console_queues,
					render: <Queue />,
				};
				break;
			case IM_CUSTOMERS:
				newContent = {
					class: classes.page_chat_customers,
					render: <IMCustomers />,
				};
				break;
			case IM_ACTIVITY:
				newContent = {
					class: classes.page_chat_activity,
					render: <IMActivity />,
				};
				break;
			case IM_STATS:
				newContent = {
					class: classes.page_chat_stats,
					render: <IMStats />,
				};
				break;
			default:
				newContent = {
					class: '',
					render: 'not Implemented',
				};
		}
		setContent(newContent);
		setOverlay(newOverlay);
		setSidebar(false);
	};

	const overlayOnClick = () => {
		setOverlay(defaultOverlayState);
	};

	return (
		<div className={overlay.class}>
			<div id={content.class}>
				<SideBar sidebar={sidebar} subMenuOnClick={subMenuOnClick} />
				<Overlay render={overlay.render} />
				<Content render={content.render} />
			</div>
		</div>
	);
};

export default StatsPage;
