/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classes from '../../../../../css/layout.module.css';
import DataTable from '../../../../../components/dataTable/DataTable';
import Section from '../../../../../components/page/Section';

const Table = (props) => {
	const { title, data } = props;
	const { headers, bodyData } = data;
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
	});

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, [headers]);

	useEffect(() => {
		if (bodyData) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: formatTbodyTr(bodyData),
				};
			});
		}
	}, [bodyData]);

	const formatThead = () => {
		return (
			<tr>
				{Object.entries(headers).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	};

	const formatTbodyTr = (data, id) => {
		const formatTd = (title, value, index) => {
			return (
				<td key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span className={classes.tb_content}>{value}</span>
				</td>
			);
		};
		return data.map((it, index) => {
			return (
				<tr key={'tr-' + index}>
					{Object.entries(it).map(([key, value], index) => {
						return formatTd(key, value, index);
					})}
				</tr>
			);
		});
	};

	let render = (
		<DataTable className={classes.stats_im_table} data={tbodyData} />
	);

	return (
		<Section
			className={classes.stats_im_tableouter}
			title={title}
			render={render}
		/>
	);
};

export default Table;
