/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from '../../css/layout.module.css';

export const LOGIN_FORM_RENDERS = (username, password, onChange, onClick) => {
	return {
		h2: 'Log In',
		inputs: [
			{
				input: {
					type: 'Text',
					label: 'Username',
					name: 'username',
					onChange: onChange,
					value: username,
				},
			},
			{
				input: {
					type: 'Password',
					label: 'Password',
					name: 'password',
					onChange: onChange,
					value: password,
				},
			},
		],
		submitButtonText: 'Sign In >',
		extra: (
			<div className={classes.form_sublinks}>
				Forgot Your Password?&nbsp;
				<a href='' onClick={onClick}>
					Click Here
				</a>
			</div>
		),
	};
};

export const TWO_FACTOR_CODE_FORM_RENDERS = (code, onChange, onClick) => {
	return {
		h2: '2-Step Verification',
		inputs: [
			{
				topText:
					'Enter the 2-stop verification sent to your registered email address.',
				input: {
					type: 'Text',
					label: 'Verification code',
					name: 'code',
					onChange: onChange,
					value: code,
				},
			},
		],
		submitButtonText: 'Submit >',
		extra: (
			<div className={classes.form_sublinks}>
				<a href='' onClick={onClick}>
					Send a new Code
				</a>
			</div>
		),
	};
};

export const FORGOTTEN_PASSWORD_FORM_RENDERS = (username, onChange) => {
	return {
		h2: 'Forgotten Password',
		inputs: [
			{
				topText: 'Please enter your username',
				input: {
					type: 'Text',
					label: 'Username',
					name: 'username',
					onChange: onChange,
					value: username,
				},
			},
		],
		submitButtonText: 'Submit >',
	};
};

export const FORGOTTEN_PASSWORD_FORM_SUCCESS_RENDERS = () => {
	return {
		h2: 'Forgotten Password',
		inputs: [
			{
				topText:
					'If the usernmae exists, we will send you a recovery email.',
			},
		],
		submitButtonText: '< Back',
	};
};

export const CHANGE_PASSWORD_FORM_RENDERS = (
	password,
	confirmPassword,
	onChange
) => {
	return {
		h2: 'Change Password',
		inputs: [
			{
				topText: 'Please enter your new password',
				input: {
					type: 'Password',
					label: 'New Password',
					name: 'password',
					onChange: onChange,
					value: password,
				},
			},
			{
				topText: 'Please confirm your new password',
				input: {
					type: 'Password',
					label: 'Confirm Password',
					name: 'confirmPassword',
					onChange: onChange,
					value: confirmPassword,
				},
			},
		],
		submitButtonText: 'Submit >',
	};
};

export const CHANGE_PASSWORD_FORM_SUCCESS_RENDERS = () => {
	return {
		h2: 'Change Password',
		inputs: [
			{
				topText: 'Please try login with the new password.',
			},
		],
		submitButtonText: '< Back',
	};
};
