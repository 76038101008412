/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from '../../css/layout.module.css';
import upArrow from '../../assets/images/icon_arrow_up.svg';
import downArrow from '../../assets/images/icon_arrow_down.svg';
import { useRecoilValue } from 'recoil';
import { authState } from '../../share/state/AuthState';

const ListItem = (props) => {
	const auth = useRecoilValue(authState);
	const { listItem, menu, menuOnClick, subMenuOnClick } = props;
	const { li, a, ul } = listItem;
	const { privileges } = auth;

	let liList = null;
	let renders = null;
	let style = null;

	if (ul) {
		liList = ul.li.reduce((cur, acc, index) => {
			if (acc.privilege) {
				if (!privileges.find((p) => p === acc.privilege)) {
					return cur;
				}
			}
			cur.push(
				<li
					key={a.name + index}
					className={`${acc.className}`}
					onClick={() => subMenuOnClick(acc.id)}
				>
					<a className={classes.hoverable}>{acc.aName}</a>
				</li>
			);
			return cur;
		}, []);
	}

	if (liList) {
		renders = (
			<ul style={{ display: `${menu.display ? 'block' : ''}` }}>
				{liList}
			</ul>
		);
		style = {
			background: `url(${
				menu.display ? downArrow : upArrow
			}) right center no-repeat`,
		};
	}

	return liList === null || (liList && liList.length !== 0) ? (
		<li
			key={a.name}
			className={liList ? classes.parent : null}
			id={li ? li.id : null}
		>
			<a
				style={style}
				key={'a' + a.name}
				data-parentname={a.dataParentname}
				className={classes.hoverable}
				onClick={() => {
					if (!liList) {
						subMenuOnClick(a.id);
					}
					menuOnClick(a.dataParentname);
				}}
			>
				{a.name}
			</a>
			{renders}
		</li>
	) : null;
};

export default ListItem;
