/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from '../../../css/layout.module.css';
import Aux from '../../../hoc/aux/Aux';

const ContactAdmin = (props) => { 
	const { onClick } = props;
	return (
		<Aux>
			<section id={classes.popup_contact}>
				<div className={classes.popup_contact_close}>
					<a onClick={onClick}>Close X</a>
				</div>
				<div className={classes.popup_contact_inner}>
					<h2>Contact Admin</h2>
					<p>
						Contact your Account Manager at Allstar for support, OR
						use the contact details below;
					</p>
					<div className={classes.contact_details}>
						<ul>
							<li>
								Telephone:{' '}
								<a href='tel:448005404051'>+44 800 540 4051</a>
							</li>
							<li>
								Email:{' '}
								<a href='mailto:info@allstarpsychics.co.uk'>
									 info@allstarpsychics.co.uk
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</Aux>
	);
};

export default ContactAdmin;
