/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getSMSCustomerCareRequest } from '../../../../../axios/api';
import Aux from '../../../../../hoc/aux/Aux';
import CustomerCareTable from './CustomerCareTable';
import Filter from './Filter';
import moment from 'moment';

const CustomerCare = () => {
	const [selectedClient, setSelectedClient] = useState(null);
	const [webParam, setWebParam] = useState({
		client: null,
		serviceId: null,
		customerNo: null,
		after: null,
		before: null,
	});
	const [paging, setPaging] = useState({
		page: 0,
		size: 25,
	});
	const [tableData, setTableData] = useState({
		data: null,
		maxPage: 0,
	});
	const [dateRange, setDateRange] = useState({
		start_date: '',
		end_date: '',
	});

	useEffect(() => {
		if (webParam.client) {
			getSMSCUstomerCare();
		}
	}, [paging]);

	const getSMSCUstomerCare = () => {
		getSMSCustomerCareRequest(webParam, paging).then((res) => {
			const data = res.data;
			setTableData({ data: data.content, maxPage: data.totalPages });
		});
	};

	const pagination = (type) => {
		if (type === 'ADD') {
			setPaging({ ...paging, page: paging.page + 1 });
		} else {
			if (webParam.page !== 0) {
				setPaging({ ...paging, page: paging.page - 1 });
			}
		}
	};

	const selectOnChange = (name, event) => {
		const index = event.selectedIndex;
		const { value } = event[index];
		switch (name) {
			case 'Client':
				setSelectedClient(value);
				setWebParam({ ...webParam, client: value });
				break;
			case 'Service':
				setWebParam({ ...webParam, serviceId: value });
				break;
			default:
				return;
		}
	};

	const dateOnChange = (name, date) => {
		const formatDate = date ? moment(date).format('DD-MM-yyyy') : null;
		if (name === 'from') {
			setDateRange({ ...dateRange, start_date: date });
			setWebParam({ ...webParam, after: formatDate });
		} else if (name === 'to') {
			setDateRange({ ...dateRange, end_date: date });
			setWebParam({ ...webParam, before: formatDate });
		}
	};

	const inputOnChange = (event) => {
		setWebParam({ ...webParam, customerNo: event.target.value });
	};

	return (
		<Aux>
			<h1>Customer Care</h1>
			<Filter
				dateRange={dateRange}
				inputValue={webParam.customerNo}
				selectOnChange={selectOnChange}
				inputOnChange={inputOnChange}
				searchOnClick={getSMSCUstomerCare}
				selectedClient={selectedClient}
				dateOnChange={dateOnChange}
			/>
			<hr />
			<CustomerCareTable
				tableData={tableData}
				page={paging.page}
				size={paging.size}
				paginationOnClick={pagination}
			/>
		</Aux>
	);
};

export default CustomerCare;
