import React from 'react';
import DatePicker from 'react-datepicker';
import classes from '../../css/layout.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelector = (props) => {
	const { divClass, dateOnChange, dateRange } = props;
	const { start_date, end_date } = dateRange;
	return (
		<div className={classes.filter_div}>
			<div className={divClass}>
				<div>
					<label htmlFor='date_from'>From</label>
					<DatePicker
						selected={start_date}
						dateFormat='dd/MM/yyyy'
						onChange={(date) => dateOnChange('from', date)}
						placeholderText='Please select a date'
						startDate={start_date}
						endDate={end_date}
						selectsStart
					/>
					<label htmlFor='date_to'>To</label>
					<DatePicker
						selected={end_date}
						dateFormat='dd/MM/yyyy'
						onChange={(date) => dateOnChange('to', date)}
						placeholderText='Please select a date'
						startDate={start_date}
						endDate={end_date}
						minDate={start_date}
						selectsEnd
					/>
				</div>
			</div>
		</div>
	);
};

export default DateSelector;
