/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { csrf, isAuthenticated } from './axios/api';
import ChangePassword from './containers/auth/ChangePassword';
import ForgotPassword from './containers/auth/ForgotPassword';
import StatsPage from './containers/pages/StatsPage';
import Login from './containers/auth/Login';

import { authState } from './share/state/AuthState';
import errorHandler from './hoc/errorHandler/ErrorHandler';
import axios from './axios/axios';

function App() {
	const [auth, setAuth] = useRecoilState(authState);

	useLayoutEffect(() => {
		csrf();
	});

	useLayoutEffect(() => {
		isAuthenticated().then((res) => {
			const data = res.data;
			setAuth({
				...auth,
				auth: true,
				username: data.username,
				privileges: data.privileges,
			});
		}).catch((err)=>{})
	}, []); //Forces csrf refresh after logout (if you know better way go for it)

	let routes = (
		<Switch>
			<Route
				exact
				path='/forgottenpassword'
				component={() => <ForgotPassword />}
			/>
			<Route
				exact
				path='/changepassword'
				component={() => <ChangePassword />}
			/>
			<Route exact path='/' component={() => <Login />} />
			<Redirect to='/' />
		</Switch>
	);

	if (auth.auth) {
		routes = (
			<Switch>
				<Route exact path='/' component={() => <StatsPage />} />
				<Redirect to='/' />
			</Switch>
		);
	}

	return <BrowserRouter>{routes}</BrowserRouter>;
}

export default errorHandler(App, axios);
