/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classes from '../../../../../css/layout.module.css';
import { randomFourDigit } from '../../../../../share/function/util';
import {
	LAST_MONTH_BUTTON,
	LAST_WEEK_BUTTON,
	THIS_MONTH_BUTTON,
	THIS_WEEK_BUTTON,
	TODAY_BUTTON,
	YESTERDAY_BUTTON,
} from '../../../../../enum/enum';
import Section from '../../../../../components/page/Section';
import DataTable from '../../../../../components/dataTable/DataTable';

const SmsBreakdown = (props) => {
	const defaultTableData = {
		thead: [],
		tbody: [],
	};
	const { data, filter } = props;
	const [tableData, setTableData] = useState(defaultTableData);

	useEffect(() => {
		setTableData(defaultTableData);

		if (data) {
			getTrow(data.map(({ timestamp }) => timestamp));
			formatTbody(data);
		}
	}, [data]);

	const getTrow = (dates) => {
		let type = null;
		switch (filter) {
			case TODAY_BUTTON:
			case YESTERDAY_BUTTON:
			case THIS_WEEK_BUTTON:
			case LAST_WEEK_BUTTON: {
				type = 'day/week';
				break;
			}
			case THIS_MONTH_BUTTON:
			case LAST_MONTH_BUTTON: {
				type = 'month';
				break;
			}
			default: {
				throw new Error('filter not exist');
			}
		}
		const tr1 = calculateTrow1(dates, type);
		const tr2 = calculateTrow2(dates, type);
		setTableData((prev) => {
			return { ...prev, thead: [tr1, tr2] };
		});
	};

	const calculateTrow1 = (dates, type) => {
		let tr1 = [
			<th key='tr1-0' rowSpan='2'>
				Type
			</th>,
		];

		const periodCount = dates
			.map((it) => {
				const date = moment(it);
				const tr1Format = type === 'day/week' ? 'MMM' : 'YYYY';
				return date.format(tr1Format);
			})
			.reduce((acc, curr) => {
				acc[curr] ? acc[curr]++ : (acc[curr] = 1);
				return acc;
			}, {});

		const rows = Object.entries(periodCount).map(([key, value], index) => {
			return (
				<th key={'tr1-' + index + 1} colSpan={value}>
					{key}
				</th>
			);
		});

		return <tr key={'tr1'}>{tr1.concat(rows)}</tr>;
	};

	const calculateTrow2 = (dates, type) => {
		const tr2Format = type === 'day/week' ? 'ddd DD' : 'MMM';
		const tr2 = dates.map((it, index) => {
			const date = moment(it);
			return <th key={'tr2-' + index}>{date.format(tr2Format)}</th>;
		});
		return <tr key={'tr2'}>{tr2}</tr>;
	};

	const formatTbody = (data) => {
		let newTbody = {};
		data.forEach((it) => {
			Object.entries(it).forEach(([key, value]) => {
				if (key !== 'timestamp') {
					if (!newTbody.hasOwnProperty(key)) {
						newTbody[key] = [value];
					} else {
						newTbody[key].push(value);
					}
				}
			});
		});

		const tbodyRender = Object.entries(newTbody).map(([key, value]) => {
			const values = value.map((it) => (
				<td key={'td' + randomFourDigit()}>{it}</td>
			));
			return (
				<tr key={'th' + randomFourDigit()}>
					<th>{key}</th>
					{values}
				</tr>
			);
		});

		setTableData((prev) => {
			return { ...prev, tbody: tbodyRender };
		});
	};

	const render = (
		<div className={classes.stats_sms_breakdown_table}>
			<DataTable data={tableData} />
		</div>
	);

	return (
		<Section
			className={classes.stats_sms_breakdown}
			title={'SMS | Breakdown'}
			render={render}
		/>
	);
};

export default SmsBreakdown;
