import React from 'react';
import classes from '../../css/layout.module.css';

const Input = (props) => {
	const { name, type, placeholder, onChange, value } = props;
	return (
		<div className={classes.filter_div}>
			<label>{name}</label>
			<input
				value={value ? value: ''}
				onChange={onChange}
				type={type}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default Input;
