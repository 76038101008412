import React from 'react';
import classes from '../../css/layout.module.css';

const Select = (props) => {
	const { label, name, selectOnChange, data } = props;

	let options = null;
	if (data) {
		data.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
		options = data.map(({ id, name }) => {
			return (
				<option key={id} value={id}>
					{name}
				</option>
			);
		});
	}

	return (
		<div className={classes.filter_div}>
			<label>{label}</label>
			<select
				onChange={(event) => {
					selectOnChange(name, event.nativeEvent.target);
				}}
			>
				<option key='0' value=''>
					Select a {name}
				</option>
				{options}
			</select>
		</div>
	);
};

export default Select;
