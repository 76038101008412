/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import classes from '../../../../../../css/layout.module.css';
import {
	MESSAGE_DETAILS,
	MESSAGE_DETAILS_CLASS,
	MESSAGE_THREAD,
	MESSAGE_THREAD_CLASS,
} from '../../../../../../enum/enum';
import { overleyState } from '../../../../../../share/state/OverlayState';
import MessageDetails from './MessageDetails';
import MessageThread from './MessageThread';

const MessageOverview = (props) => {
	const { onClick, id, direction } = props;
	const [messageTab, setMessageTab] = useState(MESSAGE_DETAILS);
	const setOverley = useSetRecoilState(overleyState);
	const [render, setRender] = useState(null);

	useEffect(() => {
		switch (messageTab) {
			case MESSAGE_DETAILS:
				setRender(<MessageDetails id={id} direction={direction} />);
				return;
			case MESSAGE_THREAD:
				setRender(<MessageThread id={id} direction={direction} />);
				return;
			default:
				throw new Error('Unknown Message Tab');
		}
	}, [messageTab]);

	const messageTabOnClick = (type) => {
		setOverley((prev) => {
			return {
				...prev,
				class:
					type === MESSAGE_DETAILS
						? MESSAGE_DETAILS_CLASS
						: MESSAGE_THREAD_CLASS,
			};
		});
		setMessageTab(type);
	};

	let h2 = direction === 0 ? 'Inbound Message' : 'OutboundMessage';

	return (
		<section className={classes.details_popup}>
			<div className={classes.details_popup_top}>
				<h2>{h2}</h2>
				<nav>
					<a onClick={() => messageTabOnClick(MESSAGE_DETAILS)}>
						Message Details
					</a>
					<a onClick={() => messageTabOnClick(MESSAGE_THREAD)}>
						Message Threads
					</a>
				</nav>
				<div className={classes.details_popup_close}>
					<a onClick={onClick}>Close X</a>
				</div>
			</div>
			<div className={classes.details_popup_bottom}>{render}</div>
		</section>
	);
};

export default MessageOverview;
