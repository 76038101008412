import React, { useState, useEffect, useCallback } from 'react';
import classes from '../../../../../css/layout.module.css';
import {
	formatTimestamp,
	getColourClass,
} from '../../../../../share/function/util';
import { DATE_TIME_FORMAT } from '../../../../../enum/enum';
import DataTable from '../../../../../components/dataTable/DataTable';

const ActivityTable = (props) => {
	const { data } = props;
	const [tbodyData, setTbodyData] = useState({
		thead: null,
		tbody: null,
	});

	const formatThead = useCallback(() => {
		const tableHeaders = {
			[classes.tb_colour]: '',
			tb_time: 'Time',
			tb_from: 'From',
			tb_to: 'To',
			tb_service: 'Service',
			[classes.tb_content]: 'Content',
		};

		return (
			<tr>
				{Object.entries(tableHeaders).map(([key, value]) => (
					<th key={key} id={key}>
						{value}
					</th>
				))}
			</tr>
		);
	}, []);

	const formatTbodyTr = (data, id, direction, free) => {
		const formatTd = (title, value, index) => {
			return (
				<td headers='tb_status' key={title + index}>
					<span className={classes.mobile_title}>{title}</span>
					<span className={classes.tb_content}>{value}</span>
				</td>
			);
		};
		return (
			<tr key={'tr-' + id} className={getColourClass(direction, free)}>
				<td headers='tb_colour'></td>
				{Object.entries(data).map(([key, value], index) => {
					return formatTd(key, value, index);
				})}
			</tr>
		);
	};

	useEffect(() => {
		setTbodyData((prev) => {
			return {
				...prev,
				thead: formatThead(),
			};
		});
	}, [formatThead]);

	useEffect(() => {
		if (data) {
			setTbodyData((prev) => {
				return {
					...prev,
					tbody: data.map((it) => {
						const data = {
							Time: formatTimestamp(
								it.timestamp,
								DATE_TIME_FORMAT
							),
							From: it.outbound ? it.user : it.customer,
							To: it.outbound ? it.customer : it.user,
							Service: it.brand,
							Content: it.body,
						};
						return formatTbodyTr(
							data,
							it.id,
							it.outbound === '0' ? 0 : 1,
							it.chargeable === '0' ? 0 : 1
						);
					}),
				};
			});
		}
	}, [data]);

	return (
		<section className={classes.activity_results}>
			<DataTable
				className={classes.activity_results_table}
				data={tbodyData}
			/>
		</section>
	);
};

export default ActivityTable;
