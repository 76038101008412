import React from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

import { forgottenPasswordState } from '../../share/state/AuthState';
import { forgotPasswordRequest } from '../../axios/api';
import {
	FORGOTTEN_PASSWORD_FORM_RENDERS,
	FORGOTTEN_PASSWORD_FORM_SUCCESS_RENDERS,
} from '../../components/auth/FormRenders';
import AuthForm from '../../components/auth/AuthForm';
import Auth from '../../components/auth/Auth';

const ForgotPassword = () => {
	const history = useHistory();
	const [forgottenPassword, setForgottenPassword] = useRecoilState(
		forgottenPasswordState
	);
	const { username, success } = forgottenPassword;
	//onChange
	const forgottenPasswordFormOnChange = (event) => {
		setForgottenPassword({
			...forgottenPassword,
			username: event.target.value,
		});
	};
	//onClick
	const redirectToHomeClickHandler = () => {
		setForgottenPassword({ ...forgottenPassword, success: false });
		history.push({ pathname: '/' });
	};
	const forgotPasswordFormSubmit = (event) => {
		event.preventDefault();
		forgotPasswordRequest(username).then((res) =>
			setForgottenPassword({ ...forgottenPassword, success: true })
		);
	};
	//render
	let render = success ? (
		<AuthForm
			render={FORGOTTEN_PASSWORD_FORM_SUCCESS_RENDERS()}
			onClick={redirectToHomeClickHandler}
		/>
	) : (
		<AuthForm
			render={FORGOTTEN_PASSWORD_FORM_RENDERS(
				username,
				forgottenPasswordFormOnChange
			)}
			onClick={forgotPasswordFormSubmit}
		/>
	);

	return <Auth render={render} />;
};

export default ForgotPassword;
