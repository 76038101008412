import { atom } from 'recoil';

export const menuState = atom({
	key: 'sidebarMenu',
	default: {
		menu_sms: {
			display: false,
		},
		menu_chat: {
			display: false,
		},
		menu_console: {
			display: false,
		},
		menu_support: {
			display: false,
		},
	},
});

export const sidebarState = atom({
	key: 'sidebar',
	default: false
})